import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import NextImage from 'next/image';

export const FooterWrapper = styled('footer')(() => ({
  width: '100%',
  flexShrink: 0,
}));

export const Pages = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.grayscale[600],
}));

export const GridWrapper = styled(Grid)(({ theme }) => ({
  paddingTop: theme.spacing(7.5),
  paddingBottom: theme.spacing(7.5),
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column-reverse',
    flexWrap: 'nowrap',
  },
}));

export const TitlePages = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  paddingBottom: theme.spacing(2),
}));

export const LinksList = styled('ul')(() => ({
  listStyle: 'none',
  margin: 0,
  padding: 0,
}));

export const LinkItem = styled(Typography)(({ theme }) => ({
  display: 'inline-block',
  cursor: 'pointer',
  color: theme.palette.common.white,
  textDecoration: 'none',
  marginBottom: theme.spacing(1),
  '&:hover': {
    textDecoration: 'underline',
  },
}));

export const TitleMembers = styled(Typography)(({ theme }) => ({
  minWidth: 370,
  [theme.breakpoints.down('md')]: {
    width: '100%',
    textAlign: 'center',
    padding: theme.spacing(2.5, 0),
    minWidth: 'unset',
  },
}));

export const Social = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.blue,
  padding: '25px 0',
}));

export const Row = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',

  [theme.breakpoints.down('md')]: {
    justifyContent: 'space-between',
  },
}));

export const SocialIcons = styled('div')(({ theme }) => ({
  paddingLeft: '55px',
  display: 'grid',
  gridTemplateColumns: 'repeat(4, 1fr)',
  columnGap: '14px',
  alignItems: 'center',

  [theme.breakpoints.down('md')]: {
    paddingLeft: 0,
  },
}));

export const Members = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  padding: '30px 0',
}));

export const MembersContent = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0 40px',

  [theme.breakpoints.down('md')]: {
    flexWrap: 'wrap',
    padding: 0,
  },
}));

export const IconWrapper = styled('div')(({ theme }) => ({
  height: '40px',
  width: '100%',
  position: 'relative',

  [theme.breakpoints.down('md')]: {
    width: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '22px 0',
  },
}));

export const IconImage = styled(NextImage)(() => ({
  objectFit: 'contain',
}));
