import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Wrapper = styled('div')(({ theme }) => ({
  maxWidth: 1200,
  margin: '0 auto',
  paddingBottom: 0,
  transition: 'padding 0.3s',

  '&:hover': {
    paddingBottom: theme.spacing(4.5),
  },
}));

export const MainLink = styled(Typography, {
  shouldForwardProp: prop => prop !== 'active',
})(({ theme, active }) => ({
  cursor: 'pointer',
  paddingBottom: theme.spacing(1),
  borderBottom: `2px solid ${
    active ? theme.palette.primary.main : 'transparent'
  }`,
}));
