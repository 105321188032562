import PropTypes from 'prop-types';
import React from 'react';

import MobileHeader from '@/components/MobileHeader/MobileHeader';
import { useMobileMediaQuery } from '@/hooks/useMobileMediaQuery';
import { useNavigationContext } from '@/hooks/useNavigationContext';

import DesktopHeader from './lib/DesktopHeader';

/**
 * Klickrent logo and main menu.
 */
function PageHeader({
  style,
  basketHidden,
  languagePagePickerHidden,
  navigationHidden,
  actionIcons,
  phoneNumber,
  mrktClassNamePhone,
  mrktClassNameMail,
}) {
  const navigationState = useNavigationContext();
  const mobileMediaQuery = useMobileMediaQuery();
  const navigation = navigationHidden ? null : navigationState;

  return mobileMediaQuery ? (
    <MobileHeader
      navigation={navigation}
      basketHidden={basketHidden}
      languagePagePickerHidden={languagePagePickerHidden}
      actionIcons={actionIcons}
      phoneNumber={phoneNumber}
      mrktClassNamePhone={mrktClassNamePhone}
      mrktClassNameMail={mrktClassNameMail}
    />
  ) : (
    <DesktopHeader
      style={style}
      basketHidden={basketHidden}
      languagePagePickerHidden={languagePagePickerHidden}
      navigation={navigation}
      actionIcons={actionIcons}
      phoneNumber={phoneNumber}
      mrktClassNamePhone={mrktClassNamePhone}
      mrktClassNameMail={mrktClassNameMail}
    />
  );
}

PageHeader.propTypes = {
  /** Css theme. */
  style: PropTypes.object,
  /** Should hide basket */
  basketHidden: PropTypes.bool,
  languagePagePickerHidden: PropTypes.bool,
  navigationHidden: PropTypes.bool,
  /** Adds additional icons in front of the default ones */
  actionIcons: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  /* Overwrites default phone number */
  phoneNumber: PropTypes.string,
  /* Class names used for tracking */
  mrktClassNamePhone: PropTypes.string,
  mrktClassNameMail: PropTypes.string,
};

PageHeader.defaultProps = {
  style: {},
  basketHidden: false,
  languagePagePickerHidden: false,
  navigationHidden: false,
  actionIcons: null,
  phoneNumber: null,
  mrktClassNamePhone: null,
  mrktClassNameMail: null,
};

export default PageHeader;
