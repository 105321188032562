import {
  Menu as MenuBase,
  menuClasses,
  MenuItem as MenuItemBase,
} from '@mui/material';
import { styled } from '@mui/material/styles';

export const Label = styled('div')(({ theme }) => ({
  fontFamily: theme.typography.fontFamilyRedesign,
  fontSize: theme.typography.pxToRem(12),
  fontWeight: theme.typography.fontWeightRegular,
  lineHeight: 1,
  letterSpacing: '0.263px',
  color: theme.palette.text.secondary,
  marginLeft: theme.spacing(0.5),
}));

export const Menu = styled(MenuBase)(() => ({
  [`& .${menuClasses.paper}`]: {
    boxShadow: '0px 2px 6px 0px rgba(0, 0, 0, 0.16)',
  },
}));

export const MenuItem = styled(MenuItemBase)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
}));
