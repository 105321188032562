import { Box, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

export const OuterWrapper = styled(Box, {
  shouldForwardProp: prop => prop !== 'hasNavigation',
})(({ hasNavigation }) => ({
  position: 'relative',
  zIndex: 1,
  ...(!hasNavigation && {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  }),
}));

export const InnerWrapper = styled(Stack, {
  shouldForwardProp: prop => prop !== 'hasNavigation',
})(({ hasNavigation }) => ({
  height: 67,
  ...(hasNavigation && {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  }),
}));
