import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Wrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: 1200,
  margin: '0 auto',
  paddingLeft: theme.pageMarginHorizontal,
  paddingRight: theme.pageMarginHorizontal,

  [theme.breakpoints.down('md')]: {
    paddingLeft: theme.pageMarginHorizontalMobile,
    paddingRight: theme.pageMarginHorizontalMobile,
  },
}));
