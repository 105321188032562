import Icon from '@mui/material/Icon';

import { pascalCaseToSnakeCase } from '@/helpers/utils';

const DynamicIcon = ({ name, color }) => {
  const iconName = pascalCaseToSnakeCase(name);

  return iconName ? <Icon color={color}>{iconName}</Icon> : null;
};

export default DynamicIcon;
