import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { internationalPagesPaths } from '@/constants/internationalPages';
import { formatPhoneToLink } from '@/helpers/utils';
import useFormatMessage from '@/hooks/useFormatMessage';

const GERMAN_SPEAKING_COUNTRIES_CODES = [
  'de',
  'de-AT',
  'de-BE',
  'de-DE',
  'de-LI',
  'de-LU',
  'de-CH',
];

export default function usePhoneNumber() {
  const formatMessage = useFormatMessage();
  const germanPhoneNumber = formatMessage('team_phone');
  const internationalPhoneNumber = formatMessage('header_phone_international');
  const [phoneNumber, setPhoneNumber] = useState(germanPhoneNumber);
  const [phoneNumberLink, setPhoneNumberLink] = useState(
    formatPhoneToLink(germanPhoneNumber)
  );
  const { pathname } = useRouter();

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      (!GERMAN_SPEAKING_COUNTRIES_CODES.includes(window.navigator.language) ||
        internationalPagesPaths.includes(pathname))
    ) {
      setPhoneNumber(internationalPhoneNumber);
      setPhoneNumberLink(formatPhoneToLink(internationalPhoneNumber));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [phoneNumber, phoneNumberLink];
}
