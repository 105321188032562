import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import SvgIcon from '@/components/ui/SvgIcon/SvgIcon';

export const SectionHeader = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(2, 4.5),
  backgroundColor: theme.palette.grayscale[50],
}));

export const ContactUsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(4.5, 0),
}));

export const Contact = styled('a')(({ theme }) => ({
  width: 274,
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  borderRadius: 3,
  border: `solid 1px ${theme.palette.grayscale[600]}`,
  margin: '0 auto',
  padding: theme.spacing(0.5, 1.5),
  boxSizing: 'border-box',
  marginBottom: theme.spacing(1.5),
}));

export const ContactContent = styled(Typography)(() => ({
  textAlign: 'center',
  flexGrow: 1,
}));

export const ContactIcon = styled(SvgIcon)(({ theme }) => ({
  width: 24,
  height: 24,
  color: theme.palette.primary.main,
}));
