import { Alert as MuiAlert, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';

import SvgIcon from '@/components/ui/SvgIcon/SvgIcon';

export const Alert = styled(MuiAlert)(({ theme }) => ({
  fontWeight: 500,
  color: theme.palette.common.darkBlue,
  marginTop: theme.spacing(1),
}));

export const SearchIcon = styled(SvgIcon)(() => ({
  position: 'relative',
  top: -8,
}));

export const InputLoader = styled(CircularProgress)(({ theme }) => ({
  position: 'relative',
  top: -8,
  marginRight: theme.spacing(1),
}));
