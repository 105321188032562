import { Box, FormHelperText } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import Checkbox from '@/components/ui/Checkbox/Checkbox';

import { FormControlLabel, Label } from './CheckboxWithLabel.style';

/**
 * Checkbox component.
 */
const CheckboxWithLabel = ({
  label,
  className,
  size,
  light,
  'data-cy': dataCy,
  tabIndex,
  disabled,
  error,
  errorMessage,
  required,
  indeterminate,
  inputProps,
  checked,
  CheckboxProps,
  value,
  onChange,
  onBlur,
  ...props
}) => {
  return (
    <div>
      <FormControlLabel
        className={className}
        control={
          <Checkbox
            data-cy={dataCy || `checkbox: ${label}`}
            size={size}
            disabled={disabled}
            light={light}
            error={error}
            tabIndex={tabIndex}
            indeterminate={indeterminate}
            inputProps={inputProps}
            checked={checked}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            {...CheckboxProps}
          />
        }
        label={
          <Label size={size} light={light}>
            {label}
            {required && '*'}
          </Label>
        }
        {...props}
      />
      {errorMessage && error && (
        <Box ml={3.3} mb={1.5} mt={0.5}>
          <FormHelperText error={error}>{errorMessage}</FormHelperText>
        </Box>
      )}
    </div>
  );
};

CheckboxWithLabel.propTypes = {
  /** Name of the group of checkboxes. */
  name: PropTypes.string,
  /** Displayed label. */
  label: PropTypes.node.isRequired,
  /** Default checkbox state. */
  checked: PropTypes.bool,
  /** Should checkbox be not clickable. */
  disabled: PropTypes.bool,
  /** The string to use as the value of the checkbox when submitting the form, if the checkbox is currently toggled on. */
  value: PropTypes.string,
  /** Callback function triggered on change checkbox value. */
  onChange: PropTypes.func,
  /** Callback function triggered on checkbox blur. */
  onBlur: PropTypes.func,
  /** Css theme. */
  className: PropTypes.string,
  /** Tab index passed to input. */
  tabIndex: PropTypes.number,
  /** Cypress hook. */
  'data-cy': PropTypes.string,
  /** Checkbox and label size. */
  size: PropTypes.oneOf(['small', 'medium']),
  /** Light version of checkbox */
  light: PropTypes.bool,
  error: PropTypes.bool,
  required: PropTypes.bool,
  errorMessage: PropTypes.string,
  indeterminate: PropTypes.bool,
  inputProps: PropTypes.object,
  CheckboxProps: PropTypes.object,
};

CheckboxWithLabel.defaultProps = {
  name: null,
  label: '',
  checked: false,
  disabled: false,
  value: 'on',
  onChange: () => {},
  onBlur: () => {},
  className: '',
  tabIndex: null,
  'data-cy': '',
  size: 'medium',
  light: false,
  error: false,
  required: false,
  errorMessage: '',
  indeterminate: false,
  inputProps: {},
  CheckboxProps: {},
};

export default CheckboxWithLabel;
