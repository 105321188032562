import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import SvgIcon from '@/components/ui/SvgIcon/SvgIcon';
import getConfig from '@/configs/global';
import { contactData } from '@/constants/internationalPages';
import { trackHeaderEmailClick } from '@/helpers/dataLayer';
import { useMobileMediaQuery } from '@/hooks/useMobileMediaQuery';

import { Wrapper, WrapperNewHeader } from './IntlHeaderEmail.style';

const IntlHeaderEmail = ({ mrktClassName }) => {
  const {
    publicRuntimeConfig: { FEAT_FLAG_NEW_PAGE_HEADER },
  } = getConfig();
  const mobileMediaQuery = useMobileMediaQuery();

  if (FEAT_FLAG_NEW_PAGE_HEADER && !mobileMediaQuery) {
    return (
      <WrapperNewHeader
        href={contactData.contactPersonEmailHref}
        className={mrktClassName || 'mrkt-intl_requestmail'}
        onClick={trackHeaderEmailClick}>
        <SvgIcon
          name="mail"
          size="small"
          sx={{ color: theme => theme.palette.common.white }}
        />
        <Typography
          component="span"
          color="common.white"
          fontSize={theme => theme.typography.pxToRem(12)}
          ml={1}>
          {contactData.contactPersonEmail}
        </Typography>
      </WrapperNewHeader>
    );
  }

  return (
    <Wrapper
      href={contactData.contactPersonEmailHref}
      className={mrktClassName || 'mrkt-intl_requestmail'}
      onClick={trackHeaderEmailClick}>
      <SvgIcon name="mail" color="primary" />
    </Wrapper>
  );
};

IntlHeaderEmail.propTypes = {
  mrktClassName: PropTypes.string,
};

IntlHeaderEmail.defaultProps = {
  mrktClassName: null,
};

export default IntlHeaderEmail;
