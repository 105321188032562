import DownArrow from '@mui/icons-material/KeyboardArrowDown';
import { IconButton, Menu, MenuItem, Stack, Typography } from '@mui/material';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useState } from 'react';

import getConfig from '@/configs/global';
import { useMobileMediaQuery } from '@/hooks/useMobileMediaQuery';

import { Label } from './LanguagePagePicker.style';
import britishFlag from './lib/british-flag.png';
import germanFlag from './lib/german-flag.png';
import polishFlag from './lib/polish-flag.png';

const languages = [
  {
    href: '/',
    label: 'Deutsch',
    image: { src: germanFlag, alt: 'Deutsche Flagge' },
    code: 'DE',
  },
  {
    href: '/intl',
    label: 'English',
    image: { src: britishFlag, alt: 'English flag' },
    code: 'EN',
  },
  {
    href: '/pl',
    label: 'Polski',
    image: { src: polishFlag, alt: 'Polska flaga' },
    code: 'PL',
  },
];

const LanguagePagePicker = () => {
  const {
    publicRuntimeConfig: { FEAT_FLAG_NEW_PAGE_HEADER },
  } = getConfig();
  const router = useRouter();
  const mobileMediaQuery = useMobileMediaQuery();

  const selectedLanguage =
    languages.find(({ href }) => href === router.pathname) || languages[0];

  const displayedLanguages = languages.filter(
    ({ href }) => href !== selectedLanguage.href
  );

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOnItemClick = href => {
    router.push(href);
    handleClose();
  };

  return (
    <Stack direction="row" alignItems="center">
      <IconButton onClick={handleClick}>
        <Image
          src={selectedLanguage.image.src}
          height="15"
          width="15"
          alt={selectedLanguage.image.alt}
          style={{
            maxWidth: '100%',
            height: 'auto',
          }}
        />
        <DownArrow sx={{ fontSize: '15px' }} color="primary" />
        {FEAT_FLAG_NEW_PAGE_HEADER && !mobileMediaQuery && (
          <Label component="div" color="text.secondary">
            {selectedLanguage.code}
          </Label>
        )}
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        {displayedLanguages.map((language, index) => (
          <MenuItem
            onClick={() => handleOnItemClick(language.href)}
            key={language.href}
            divider={index !== displayedLanguages.length - 1}>
            <Stack direction="row" alignItems="center" spacing={4}>
              <Image
                src={language.image.src}
                height="15"
                width="15"
                alt={language.image.alt}
              />
              <Typography pr={3}>{language.label}</Typography>
            </Stack>
          </MenuItem>
        ))}
      </Menu>
    </Stack>
  );
};

export default LanguagePagePicker;
