import WarningIcon from '@mui/icons-material/Warning';

import useFormatMessage from '@/hooks/useFormatMessage';

import { Banner } from './PhoneOutageBanner.style';

const PhoneOutageBanner = () => {
  const formatMessage = useFormatMessage();
  return (
    <Banner variant="body2">
      <WarningIcon sx={{ marginRight: 2, marginLeft: 0.5 }} />
      {formatMessage('infoBanner_text')}
    </Banner>
  );
};

export default PhoneOutageBanner;
