import { AccountCircle, Receipt } from '@mui/icons-material';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React from 'react';

import HeaderEmail from '@/components/HeaderEmail/HeaderEmail';
import HeaderPhone from '@/components/HeaderPhone/HeaderPhone';
import IntlHeaderEmail from '@/components/IntlHeaderEmail/IntlHeaderEmail';
import PageMargins from '@/components/ui/PageMargins/PageMargins';
import getConfig from '@/configs/global';
import { internationalPagesPaths } from '@/constants/internationalPages';
import { trackHeaderPhoneClick } from '@/helpers/dataLayer';
import useFormatMessage from '@/hooks/useFormatMessage';

const {
  publicRuntimeConfig: { FEAT_FLAG_HIDE_PHONE_NUMBER },
} = getConfig();

const UpperBar = ({ phoneNumber, mrktClassNamePhone, mrktClassNameMail }) => {
  const { pathname } = useRouter();
  const formatMessage = useFormatMessage();

  return (
    <Box bgcolor="primary.main">
      <PageMargins>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          color="common.white"
          sx={{ height: 35 }}>
          <Stack
            direction="row"
            alignSelf="flex-start"
            alignItems="center"
            sx={{ height: '100%' }}>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Receipt color="common.white" fontSize="small" />
              <Typography
                variant="captionRedesign"
                color="common.white"
                lineHeight={1}
                letterSpacing="0.263px"
                mr={1}>
                {formatMessage('header_constructionTechnology')}
              </Typography>
            </Stack>
            <Divider
              orientation="vertical"
              sx={{
                height: 20,
                borderColor: theme => theme.palette.primary.dark,
                mx: 1,
              }}
            />
            <Stack direction="row" alignItems="center" spacing={1}>
              <AccountCircle color="common.white" fontSize="small" />
              <Typography
                variant="captionRedesign"
                color="common.white"
                lineHeight={1}
                letterSpacing="0.263px"
                mr={1}>
                {formatMessage('header_personalCommunication')}
              </Typography>
            </Stack>
          </Stack>
          <Stack
            alignSelf="flex-end"
            direction="row"
            alignItems="center"
            sx={{ height: '100%' }}>
            {internationalPagesPaths.includes(pathname) ? (
              <IntlHeaderEmail mrktClassName={mrktClassNameMail} />
            ) : (
              <HeaderEmail mrktClassName={mrktClassNameMail} />
            )}

            {!FEAT_FLAG_HIDE_PHONE_NUMBER && (
              <>
                <Divider
                  orientation="vertical"
                  sx={{
                    height: 20,
                    borderColor: theme => theme.palette.primary.dark,
                    mx: 1,
                  }}
                />
                <HeaderPhone
                  phoneNumber={phoneNumber}
                  mrktClassName={mrktClassNamePhone}
                  color="common.white"
                  onClick={trackHeaderPhoneClick}
                />
              </>
            )}
          </Stack>
        </Stack>
      </PageMargins>
    </Box>
  );
};

UpperBar.propTypes = {
  /* Overwrites default phone number */
  phoneNumber: PropTypes.string,
  /* Class names used for tracking */
  mrktClassNamePhone: PropTypes.string,
  mrktClassNameMail: PropTypes.string,
};

export default UpperBar;
