import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Wrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-around',
}));

export const GridWrapper = styled('div')(() => ({
  display: 'inline-grid',
  gridTemplateColumns: 'repeat(5, auto)',
}));

export const SecondWrapper = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(4.5),
  [`& + ${SecondWrapper}`]: {
    paddingLeft: theme.spacing(6),
  },
  '&:nth-of-type(6)': {
    // first column in second row should be aligned to left
    paddingLeft: 0,
  },
}));

export const SecondaryLink = styled(Typography)(() => ({
  '&:hover': {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
}));

export const TertiaryLink = styled(SecondaryLink)(({ theme }) => ({
  color: theme.palette.grayscale[700],
}));
