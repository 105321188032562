import { Grid, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import BottomLink from '@/components/PageHeaderNew/BottomLink/BottomLink';
import Partners from '@/components/PageHeaderNew/Partners/Partners';

const Footer = ({ hasSideSection, sideSectionType, partners, bottomLink }) => {
  const getColumnSpan = () => {
    const numberOfColumns = hasSideSection && sideSectionType === 'big' ? 4 : 3;
    if (!bottomLink || !partners?.length) {
      return numberOfColumns;
    }
    return numberOfColumns / 2;
  };

  if (!bottomLink && !partners?.length) {
    return null;
  }

  return (
    <Grid
      container
      item
      xs={12}
      columns={hasSideSection && sideSectionType === 'big' ? 4 : 3}
      columnSpacing={2.5}>
      {partners?.length && (
        <Grid item xs={getColumnSpan()}>
          <Stack height="100%" justifyContent="flex-end">
            <Partners partners={partners} />
          </Stack>
        </Grid>
      )}
      {bottomLink && (
        <Grid item xs={getColumnSpan()}>
          <Stack height="100%" justifyContent="flex-end">
            <BottomLink bottomLink={bottomLink} />
          </Stack>
        </Grid>
      )}
    </Grid>
  );
};

Footer.propTypes = {
  hasSideSection: PropTypes.bool.isRequired,
  sideSectionType: PropTypes.string.isRequired,
  partners: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      attributes: PropTypes.shape({
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
      }).isRequired,
    })
  ),
  bottomLink: PropTypes.string,
};

export default Footer;
