import { Close as CloseIcon } from '@mui/icons-material';
import { Dialog, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import useFormatMessage from '@/hooks/useFormatMessage';

import DialogContent from './DialogContent/DialogContent';

const ContactFormDialog = ({ open, onClose }) => {
  const formatMessage = useFormatMessage();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      scroll="body"
      PaperProps={{ 'data-cy': 'headerEmailDialog' }}>
      <IconButton
        aria-label={formatMessage('contactForm_cancel')}
        onClick={onClose}
        size="small"
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          borderRadius: '50%',
        }}
        data-cy="headerEmailClose">
        <CloseIcon />
      </IconButton>
      {open && <DialogContent onClose={onClose} />}
    </Dialog>
  );
};

ContactFormDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ContactFormDialog;
