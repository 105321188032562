import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Label = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.fontFamilyRedesign,
  fontSize: theme.typography.pxToRem(12),
  fontWeight: theme.typography.fontWeightRegular,
  lineHeight: 1,
  letterSpacing: '0.263px',
  cursor: 'pointer',
}));
