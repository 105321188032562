const BASE_PATHNAME = '/blog';
const ALL_CATEGORY_PATHNAME = '/all';

const blogConfig = {
  // `basePathname` is implicitly tied to `pages/blog` directory. Both must match.
  basePathname: BASE_PATHNAME,
  // this is a pathname for a non-existent category that is supposed to contain all blog posts
  allCategoryPathname: ALL_CATEGORY_PATHNAME,
  // homepage directory
  blogHomepage: `${BASE_PATHNAME}${ALL_CATEGORY_PATHNAME}`,
};

export default blogConfig;
