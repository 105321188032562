import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Level1 from './Level1/Level1';
import Level2 from './Level2/Level2';
import Level3 from './Level3/Level3';
import { Levels, Wrapper } from './MobileNavigation.style';

function MobileNavigation({ navigation, closeNavigation, loginMenuHidden }) {
  const [activeLevel, setActiveLevel] = useState(1);
  const [level1, setLevel1] = useState(null);
  const [level2, setLevel2] = useState(null);

  const menuItems = navigation.data.attributes.menu_items?.data || [];
  const navbarLinks = navigation.data.attributes.navbar_links?.data || [];

  const scrollToTop = () => {
    const overlay = document.getElementById('overlayWrapper');
    overlay.scrollTop = 0;
  };

  const handleClickOnLevel1 = link => {
    setLevel1(link);
    setLevel2(null);
    setActiveLevel(2);

    scrollToTop();
  };

  const handleClickOnLevel2 = link => {
    setLevel2(link);
    setActiveLevel(3);

    scrollToTop();
  };

  return (
    <Wrapper data-cy="mobile navigation">
      <Levels activeLevel={activeLevel}>
        <Level1
          menuItems={menuItems}
          activeLevel={activeLevel}
          onClick={handleClickOnLevel1}
          closeNavigation={closeNavigation}
          navbarLinks={navbarLinks}
          loginMenuHidden={loginMenuHidden}
        />
        <Level2
          level={level1}
          activeLevel={activeLevel}
          onGoBackClick={() => setActiveLevel(1)}
          onMenuItemClick={handleClickOnLevel2}
          closeNavigation={closeNavigation}
        />
        <Level3
          level={level2}
          activeLevel={activeLevel}
          closeNavigation={closeNavigation}
          onGoBackClick={() => setActiveLevel(2)}
        />
      </Levels>
    </Wrapper>
  );
}

MobileNavigation.propTypes = {
  closeNavigation: PropTypes.func.isRequired,
  navigation: PropTypes.shape({
    data: PropTypes.shape({
      attributes: PropTypes.shape({
        navbar_links: PropTypes.shape({
          data: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.number.isRequired,
              attributes: PropTypes.shape({
                link: PropTypes.string.isRequired,
                title: PropTypes.string.isRequired,
              }).isRequired,
            })
          ),
        }),
        menu_items: PropTypes.shape({
          data: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.number.isRequired,
              attributes: PropTypes.shape({
                title: PropTypes.string.isRequired,
              }).isRequired,
            })
          ),
        }),
      }).isRequired,
    }).isRequired,
  }).isRequired,
  loginMenuHidden: PropTypes.bool,
};

export default MobileNavigation;
