export const europeCountryCodesAndNames = {
  AD: 'Andorra',
  AL: 'Albania',
  AT: 'Austria',
  BA: 'Bosnia and Herzegovina',
  BE: 'Belgium',
  BG: 'Bulgaria',
  BY: 'Belarus',
  CH: 'Switzerland',
  CY: 'Cyprus',
  DE: 'Germany',
  DK: 'Denmark',
  EE: 'Estonia',
  ES: 'Spain',
  FI: 'Finland',
  FR: 'France',
  GE: 'Georgia',
  GR: 'Greece',
  HR: 'Croatia',
  HU: 'Hungary',
  IE: 'Ireland',
  IS: 'Iceland',
  IT: 'Italy',
  KZ: 'Kazakhstan',
  LI: 'Liechtenstein',
  LT: 'Lithuania',
  LU: 'Luxembourg',
  LV: 'Latvia',
  MC: 'Monaco',
  ME: 'Montenegro',
  MT: 'Malta',
  NO: 'Norway',
  PL: 'Poland',
  PT: 'Portugal',
  RO: 'Romania',
  RS: 'Serbia',
  RU: 'Russia',
  SE: 'Sweden',
  SI: 'Slovenia',
  SK: 'Slovakia',
  SM: 'San Marino',
  TR: 'Turkey',
  UA: 'Ukraine',
  MK: 'North Macedonia',
  NL: 'Netherlands',
  MD: 'Republic of Moldova',
  CZ: 'Czechia',
  GB: 'United Kingdom',
};

export const europeCountryCodes = Object.keys(europeCountryCodesAndNames);
