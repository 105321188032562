import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const LoginAction = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primaryColorShades.p4,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  gap: theme.spacing(1.5),
  padding: theme.spacing(1.5, 2),
  cursor: 'pointer',
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

export const Label = styled('div')(({ theme }) => ({
  fontFamily: theme.typography.fontFamilyRedesign,
  fontSize: theme.typography.pxToRem(16),
  fontWeight: theme.typography.fontWeightRegular,
  lineHeight: 1,
  letterSpacing: '0.15px',
  color: theme.palette.text.primary,
}));
