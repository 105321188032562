import { Autocomplete as MUIAutocomplete } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import Input from '@/components/ui/Input/Input';

import { Popper } from './Autocomplete.style';

const Autocomplete = ({
  label,
  TextFieldProps,
  autoWidth,
  autoComplete,
  PopperProps,
  id,
  ...props
}) => {
  return (
    <MUIAutocomplete
      size="small"
      renderInput={params => {
        return (
          <Input
            {...params}
            hiddenLabel={!label}
            label={label}
            autoComplete={autoComplete}
            {...TextFieldProps}
            InputProps={{ ...params.InputProps, ...TextFieldProps.InputProps }}
            inputProps={{ ...params.inputProps, ...TextFieldProps.inputProps }}
          />
        );
      }}
      PopperComponent={({ children, ...props }) => (
        <Popper
          autoWidth={autoWidth}
          placement="bottom-start"
          {...props}
          {...PopperProps}>
          {children}
        </Popper>
      )}
      id={id}
      {...props}
    />
  );
};

/** More info about available props in documentation https://mui.com/api/autocomplete/ */
Autocomplete.propTypes = {
  label: PropTypes.string,
  /** Popper width based on content width */
  autoWidth: PropTypes.bool,
  TextFieldProps: PropTypes.object,
  PopperProps: PropTypes.object,
  /** Id needed not to generate random id during testing */
  id: PropTypes.string,
  autoComplete: PropTypes.string,
};
Autocomplete.defaultProps = {
  label: '',
  autoWidth: false,
  TextFieldProps: { InputProps: {}, inputProps: {} },
  PopperProps: {},
  id: null,
  autoComplete: 'off',
};

export default Autocomplete;
