import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import useFormatMessage from '@/hooks/useFormatMessage';

import { Label, LoginAction } from './LoginActionsMobile.style';

const LoginActionsMobile = ({
  onLogout = () => {},
  onGoToPage = () => {},
  authenticated = false,
  loginPagePath,
}) => {
  const formatMessage = useFormatMessage();

  if (authenticated) {
    return (
      <Box>
        <LoginAction onClick={() => onGoToPage('/account/create')}>
          <AccountCircleIcon fontSize="small" color="primary" />
          <Label>{formatMessage('header_account_yourData')}</Label>
        </LoginAction>
        <LoginAction onClick={() => onGoToPage('/bookings')}>
          <ReceiptIcon fontSize="small" color="primary" />
          <Label>{formatMessage('header_account_yourBookings')}</Label>
        </LoginAction>
        <LoginAction onClick={onLogout}>
          <LogoutIcon fontSize="small" color="primary" />
          <Label>{formatMessage('header_account_logOut')}</Label>
        </LoginAction>
      </Box>
    );
  }

  return (
    <LoginAction onClick={() => onGoToPage(loginPagePath)}>
      <AccountCircleIcon
        fontSize="small"
        sx={{ color: theme => theme.palette.grayscale[500] }}
      />
      <Label>{formatMessage('header_account_logIn')}</Label>
    </LoginAction>
  );
};

LoginActionsMobile.propTypes = {
  onLogout: PropTypes.func.isRequired,
  onGoToPage: PropTypes.func.isRequired,
  authenticated: PropTypes.bool.isRequired,
  loginPagePath: PropTypes.string.isRequired,
};

export default LoginActionsMobile;
