import countryCodes from 'country-codes-list';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import Autocomplete from '@/components/ui/Autocomplete/Autocomplete';
import { europeCountryCodes } from '@/helpers/euCountries';
import useFormatMessage from '@/hooks/useFormatMessage';

import { CountryName, CountryPrefix } from './CountryPhonePrefixSelect.style';

const countryCodesList = countryCodes.customList(
  'countryCode',
  `+{countryCallingCode}`
);

const countryNamesList = countryCodes.customList(
  'countryCode',
  `{countryNameEn}`
);

const sortCountries = countries =>
  countries.sort((a, b) =>
    a.countryName.trim().localeCompare(b.countryName.trim(), 'de')
  );

const getSortedCountries = translate => {
  return sortCountries(
    Object.entries(countryCodesList)
      .filter(
        ([countryCode, callingCode]) =>
          europeCountryCodes.includes(countryCode) && callingCode.length > 1
      )
      .map(([k, v]) => ({
        value: k,
        label: v,
        countryName: translate(countryNamesList[k]),
      }))
  );
};

const CountryPhonePrefixSelect = ({
  id,
  onChange,
  value,
  inputRef,
  error,
  required,
  ...props
}) => {
  const formatMessage = useFormatMessage();

  const countries = useMemo(() => getSortedCountries(formatMessage), [
    formatMessage,
  ]);

  const countryCodeValue = useMemo(
    () => countries.find(el => el.value === value),
    [countries, value]
  );

  return (
    <Autocomplete
      options={countries}
      getOptionLabel={option => option.label}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      value={countryCodeValue}
      renderOption={(props, option) => (
        <div {...props} key={option.countryName}>
          <CountryPrefix>{option.label}</CountryPrefix>
          <CountryName>{option.countryName}</CountryName>
        </div>
      )}
      onChange={(e, newValue) => onChange(newValue.value)}
      sx={{ width: 95 }}
      autoWidth
      disableClearable
      id={id}
      TextFieldProps={{ inputRef, error, required }}
      {...props}
    />
  );
};

CountryPhonePrefixSelect.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  error: PropTypes.bool,
  required: PropTypes.bool,
  inputRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
};

CountryPhonePrefixSelect.defaultProps = {
  value: '',
  error: false,
  required: true,
  id: null,
};

export default CountryPhonePrefixSelect;
