import { Stack, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import HeaderLogoBase from '@/components/HeaderLogo/HeaderLogo';

export const Wrapper = styled(Stack)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  zIndex: theme.zIndex.appBar,
  backgroundColor: theme.palette.common.white,
  boxShadow: '0px 4px 8px 0px rgba(0, 0, 0, 0.15)',
}));

export const PhoneNumber = styled(Typography)(({ theme }) => ({
  width: '100%',
  height: 46,
  color: theme.palette.primary.main,
  background: theme.palette.primary.light,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  transition: 'all 0.3s linear',
}));

export const LogoRow = styled('div')(({ theme }) => ({
  height: 54,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: theme.spacing(0, 1.5),
}));

export const IconsWrapper = styled(Stack, {
  shouldForwardProp: prop => prop !== 'autoWidth',
})(({ theme, autoWidth }) => ({
  width: 130,
  ...(autoWidth && {
    [theme.breakpoints.down(430)]: {
      width: 'auto',
    },
  }),
}));

export const HeaderLogo = styled(HeaderLogoBase)(() => ({
  flex: 1,
  minWidth: 100,
}));
