import { formHelperTextClasses, Typography } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';

import SuccessIconBase from '@/assets/success.svg';
import MuiButton from '@/components/ui/Button/Button';
import CheckboxWithLabel from '@/components/ui/CheckboxWithLabel/CheckboxWithLabel';
import MuiLoadingButton from '@/components/ui/LoadingButton/LoadingButton';

export const Button = styled(MuiButton)(() => ({
  maxWidth: 324,
}));

export const LoadingButton = styled(MuiLoadingButton)(() => ({
  maxWidth: 324,
}));

export const Form = styled('form')(({ theme }) => ({
  [`.${formHelperTextClasses.root}`]: {
    marginBottom: theme.spacing(0.5),
  },
}));

export const SuccessIcon = styled(SuccessIconBase)(() => ({
  left: -40,
  position: 'relative',
}));

export const Alert = styled(MuiAlert)(({ theme }) => ({
  backgroundColor: 'transparent',
  fontSize: theme.typography.pxToRem(12),
  alignItems: 'center',
  color: theme.palette.common.darkBlue,
  '& .MuiAlert-icon': {
    color: theme.palette.common.darkBlue,
  },
}));

export const LinkItem = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.darkBlue,
  fontWeight: 500,
  fontSize: theme.typography.pxToRem(12),
  textDecoration: 'underline',
}));

export const Checkbox = styled(CheckboxWithLabel)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  '& .MuiFormControlLabel-label > *': {
    fontSize: theme.typography.pxToRem(12),
    marginLeft: theme.spacing(1),
  },
}));
