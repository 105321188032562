import MuiButton from '@mui/material/Button';
import { styled } from '@mui/material/styles';

export const ButtonStyled = styled(MuiButton, {
  shouldForwardProp: prop => !['rounded', 'disableElevation'].includes(prop),
})(({ rounded, disableElevation }) => ({
  ...(rounded && { borderRadius: 24 }),
  ...(disableElevation && {
    boxShadow: 'none',
    '&:hover': { boxShadow: 'none' },
  }),
}));
