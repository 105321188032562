import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LogoutIcon from '@mui/icons-material/Logout';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { IconButton } from '@mui/material';
import Link from 'next/link';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import useFormatMessage from '@/hooks/useFormatMessage';

import { Label, Menu, MenuItem } from './LoginActionsDesktop.style';

const LoginActionsDesktop = ({
  onLogout = () => {},
  onGoToPage = () => {},
  authenticated = false,
  pathname = '',
  loginPagePath,
}) => {
  const formatMessage = useFormatMessage();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    handleClose();
    onLogout();
  };

  const handleGoToPage = path => () => {
    onGoToPage(path);
    handleClose();
  };

  const expandIconStyles = { fontSize: '15px', ml: 0.25 };

  return authenticated ? (
    <>
      <IconButton size="small" onClick={handleClick}>
        <AccountCircleIcon fontSize="small" color="primary" />
        <Label>{formatMessage('header_account_yourAccount')}</Label>
        {open ? (
          <ExpandLessIcon sx={expandIconStyles} color="primary" />
        ) : (
          <ExpandMoreIcon sx={expandIconStyles} color="primary" />
        )}
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem
          divider
          onClick={handleGoToPage('/account/create')}
          selected={pathname.startsWith('/account')}>
          <AccountCircleIcon fontSize="small" color="primary" />
          <Label>{formatMessage('header_account_yourData')}</Label>
        </MenuItem>
        <MenuItem
          divider
          onClick={handleGoToPage('/bookings')}
          selected={pathname.startsWith('/bookings')}>
          <ReceiptIcon fontSize="small" color="primary" />
          <Label>{formatMessage('header_account_yourBookings')}</Label>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <LogoutIcon fontSize="small" color="primary" />
          <Label>{formatMessage('header_account_logOut')}</Label>
        </MenuItem>
      </Menu>
    </>
  ) : (
    <IconButton size="small" component={Link} href={loginPagePath}>
      <AccountCircleIcon
        fontSize="small"
        sx={{ color: theme => theme.palette.grayscale[500] }}
      />
      <Label>{formatMessage('header_account_logIn')}</Label>
    </IconButton>
  );
};

LoginActionsDesktop.propTypes = {
  onLogout: PropTypes.func.isRequired,
  onGoToPage: PropTypes.func.isRequired,
  authenticated: PropTypes.bool.isRequired,
  pathname: PropTypes.string.isRequired,
};

export default LoginActionsDesktop;
