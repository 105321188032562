import { FormControlLabel as MuiFormControlLabel } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Label = styled('div', {
  shouldForwardProp: prop => !['size', 'light'].includes(prop),
})(({ theme, size, light }) => [
  {
    paddingTop: size === 'small' ? 11 : 6,
    a: {
      textDecoration: 'underline',
      color: theme.palette.primary.main,
    },
  },
  size === 'small' ? theme.typography.label : theme.typography.body1,
  light && {
    color: theme.palette.common.white,
    a: {
      color: theme.palette.common.white,
    },
  },
]);

export const FormControlLabel = styled(MuiFormControlLabel, {
  shouldForwardProp: prop => prop !== 'light',
})(() => ({
  alignItems: 'flex-start',
  marginLeft: -8,
}));
