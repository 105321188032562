import { Close as CloseIcon } from '@mui/icons-material';
import { IconButton, Stack } from '@mui/material';
import { isPast } from 'date-fns';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useState } from 'react';

import Button from '@/components/ui/Button/Button';
import getConfig from '@/configs/global';
import { pathsToExclude } from '@/constants/campaignBannerPages';
import { trackCountdownBannerCTA } from '@/helpers/dataLayer';

import { BannerWrapper } from './CountdownBanner.style';

const Countdown = dynamic(() => import('./lib/Countdown'), { ssr: false });

const CountdownBanner = ({ onBannerClose = () => {} }) => {
  const startDate = new Date('2024-09-02T00:00:00+02:00');
  const endDate = new Date('2024-09-30T23:59:00+02:00');

  const { pathname } = useRouter();

  const [countdownBannerOpen, setCountdownBannerOpen] = useState(true);
  const {
    publicRuntimeConfig: { FEAT_FLAG_CAMPAIGN_BANNER },
  } = getConfig();

  const showCountdownBanner =
    FEAT_FLAG_CAMPAIGN_BANNER &&
    countdownBannerOpen &&
    !isPast(endDate) &&
    !pathsToExclude.some(path => pathname.startsWith(path));

  const handleBannerClose = () => {
    setCountdownBannerOpen(false);
    onBannerClose?.();
  };

  if (!showCountdownBanner) return null;

  return (
    <BannerWrapper>
      <Stack direction={['column', 'row']} gap={[0.75, 3]} alignItems="center">
        <Countdown
          startDate={startDate}
          endDate={endDate}
          onCountdownComplete={() => {
            if (isPast(endDate)) {
              handleBannerClose();
            }
          }}
        />
        <Button
          variant="contained"
          disableElevation
          color="primaryLight"
          component="a"
          href="https://go.klickrent.de/herbst-sale-2024?Gutschein_Website=HERBSTSALE24"
          target="_blank"
          onClick={trackCountdownBannerCTA}>
          Zur Aktion!
        </Button>
      </Stack>
      <IconButton
        aria-label="Close banner"
        onClick={handleBannerClose}
        size="small"
        color="primaryLight"
        sx={{
          position: 'absolute',
          right: [4, 8],
          borderRadius: '50%',
          '&:hover': {
            background: 'none',
          },
        }}>
        <CloseIcon />
      </IconButton>
    </BannerWrapper>
  );
};

export default CountdownBanner;

CountdownBanner.propTypes = {
  onBannerClose: PropTypes.func,
};
