import { Stack, Typography } from '@mui/material';
import React from 'react';

import useFormatMessage from '@/hooks/useFormatMessage';

import { EmptyBasketImage } from './EmptyBasketContent.style';

const EmptyBasketContent = () => {
  const formatMessage = useFormatMessage();
  return (
    <Stack p={2} alignItems="center">
      <EmptyBasketImage />
      <Typography align="center" variant="h4" my={1.5}>
        {formatMessage('basketOverlay_emptyHeader')}
      </Typography>
      <Typography align="center" mb={1.5}>
        {formatMessage('basketOverlay_emptyText')}
      </Typography>
    </Stack>
  );
};

export default EmptyBasketContent;
