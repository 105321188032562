export const getChildren = link => {
  if (!link) {
    return [];
  }
  return link.attributes?.navbar_links?.data || [];
};

export const sortByTitle = menuItems => {
  return menuItems.sort((a, b) =>
    a.attributes.title > b.attributes.title ? 1 : -1
  );
};
