/* eslint-disable react/jsx-no-target-blank */
import { Grid, Typography } from '@mui/material';
import Link from 'next/link';
import React from 'react';

import SocialIcon3 from '@/assets/facebook.svg';
import SocialIcon4 from '@/assets/instagram.svg';
import SocialIcon2 from '@/assets/linkedin.svg';
import SocialIcon1 from '@/assets/youtube.svg';
import Newsletter from '@/components/Newsletter/Newsletter';
import PageMargins from '@/components/ui/PageMargins/PageMargins';
import blogConfig from '@/configs/blog';
import useFormatMessage from '@/hooks/useFormatMessage';

import {
  FooterWrapper,
  GridWrapper,
  IconImage,
  IconWrapper,
  LinkItem,
  LinksList,
  Members,
  MembersContent,
  Pages,
  Row,
  Social,
  SocialIcons,
  TitleMembers,
  TitlePages,
} from './Footer.style';
import memberIcon1 from './lib/logo-bbi.png';
import memberIcon2 from './lib/logo-era.png';
import memberIcon3 from './lib/logo-ipaf.png';
import memberIcon4 from './lib/logo-vdbum.png';

const links = [
  {
    id: 'footer_blog',
    link: blogConfig.blogHomepage,
    target: '_self',
    rel: '',
  },
  {
    id: 'footer_about',
    link: '/about',
    target: '_self',
    rel: '',
  },
  {
    id: 'footer_company',
    link: 'https://group.klickrent.de/',
    target: 'blank',
    rel: 'noopener noreferrer',
  },
  {
    id: 'footer_jobs',
    link: 'https://group.klickrent.de/jobs',
    target: '_blank',
    rel: 'noopener noreferrer',
  },
  {
    id: 'footer_press',
    link: 'https://group.klickrent.de/presse',
    target: '_blank',
    rel: 'noopener noreferrer',
  },
];

const linksLegal = [
  {
    id: 'footer_imprint',
    link: '/imprint',
  },
  {
    id: 'footer_datasec',
    link: '/data-privacy',
  },
  {
    id: 'footer_copyright',
    link: '/copyright',
  },
  {
    id: 'footer_tos',
    link: '/tos',
  },
  {
    id: 'footer_cookie_notice',
    link: '/cookie-notice',
  },
  {
    id: 'footer_compliance',
    link: 'https://www.zeppelin.com/de/de/verantwortung/compliance/',
    rel: 'noopener noreferrer',
  },
];

const iconsSocial = [
  {
    id: 'youtube',
    link: 'https://www.youtube.com/channel/UCfOURWINuZf9vu334BzI-kg',
    icon: <SocialIcon1 />,
    rel: 'noopener noreferrer',
    title: 'Youtube',
  },
  {
    id: 'linkedin',
    link: 'https://www.linkedin.com/company/klickrent-gmbh/',
    icon: <SocialIcon2 />,
    rel: 'noopener noreferrer',
    title: 'Linkedin',
  },
  {
    id: 'facebook',
    link: 'https://www.facebook.com/klickrent/',
    icon: <SocialIcon3 />,
    rel: 'noopener noreferrer',
    title: 'Facebook',
  },
  {
    id: 'instagram',
    link: 'https://www.instagram.com/klickrent.de/',
    icon: <SocialIcon4 />,
    rel: 'noopener noreferrer',
    title: 'Instagram',
  },
];

const iconsMembers = [
  {
    id: 'bbi',
    icon: memberIcon1,
    alt: 'bbi logo',
  },
  {
    id: 'era',
    icon: memberIcon2,
    alt: 'European Rental Association logo',
  },
  {
    id: 'ipaf',
    icon: memberIcon3,
    alt: 'IPAF logo',
  },
  {
    id: 'vdbum',
    icon: memberIcon4,
    alt: 'VDBUM logo',
  },
];

/**
 * Footer section.
 */
const Footer = () => {
  const formatMessage = useFormatMessage();

  return (
    <FooterWrapper>
      <Pages>
        <PageMargins>
          <GridWrapper container>
            <Grid container item xs={12} md={6}>
              <Grid item xs={6} md={4}>
                <TitlePages variant="body2">
                  {formatMessage('footer_links_head')}
                </TitlePages>
                <LinksList>
                  {links.map(({ id, link, rel, target }) => (
                    <li key={id}>
                      <LinkItem
                        component={Link}
                        variant="body1"
                        id={id}
                        target={target}
                        className={`mrkt-${id}`}
                        rel={rel}
                        href={link}>
                        {formatMessage(id)}
                      </LinkItem>
                    </li>
                  ))}
                </LinksList>
              </Grid>
              <Grid item xs={6} md={4}>
                <TitlePages variant="body2">
                  {formatMessage('footer_legal_head')}
                </TitlePages>
                <LinksList>
                  {linksLegal.map(({ id, link, rel }) => (
                    <li key={id}>
                      <LinkItem
                        component={Link}
                        variant="body1"
                        id={id}
                        target="_blank"
                        className={`mrkt-${id}`}
                        rel={rel}
                        href={link}>
                        {formatMessage(id)}
                      </LinkItem>
                    </li>
                  ))}
                </LinksList>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} mb={{ xs: 5, md: 0 }}>
              <Newsletter />
            </Grid>
          </GridWrapper>
        </PageMargins>
      </Pages>

      <Social>
        <PageMargins>
          <Row>
            <Typography variant="body2" color="common.white">
              {formatMessage('footer_social')}
            </Typography>
            <SocialIcons>
              {iconsSocial.map(({ id, link, icon, rel, title }) => (
                <a
                  id={id}
                  title={title}
                  href={link}
                  target="_blank"
                  key={id}
                  rel={rel}>
                  {icon}
                </a>
              ))}
            </SocialIcons>
          </Row>
        </PageMargins>
      </Social>
      <Members>
        <PageMargins>
          <MembersContent>
            <TitleMembers variant="body2">
              {formatMessage('footer_memberships')}
            </TitleMembers>
            {iconsMembers.map(({ id, icon, alt }) => (
              <IconWrapper key={id}>
                <IconImage src={icon} alt={alt} fill sizes="170px" />
              </IconWrapper>
            ))}
          </MembersContent>
        </PageMargins>
      </Members>
    </FooterWrapper>
  );
};

export default Footer;
