import { Stack } from '@mui/material';
import Link from 'next/link';
import PropTypes from 'prop-types';
import React from 'react';

import DynamicIcon from '@/components/DynamicIcon/DynamicIcon';
import StrapiImage from '@/components/StrapiImage/StrapiImage';

import {
  Button,
  CardText,
  CardTitle,
  SideSectionImage,
} from './SideSectionCard.style';

const getIconProps = navbarButton => {
  if (navbarButton.icon) {
    const propertyName =
      navbarButton.iconPosition === 'end' ? 'endIcon' : 'startIcon';
    return {
      [propertyName]: (
        <DynamicIcon name={navbarButton.icon} color="common.white" />
      ),
    };
  }
  return null;
};

const SideSectionCard = ({ card, onButtonClick }) => {
  return (
    <Stack>
      <SideSectionImage cardSize={card.cardSize}>
        <StrapiImage data={card.image.data} fill priority sizes="330px" />
      </SideSectionImage>
      <CardTitle>{card.title}</CardTitle>
      <CardText
        dangerouslySetInnerHTML={{
          __html: card.content,
        }}
      />
      {card.navbarButton && (
        <Button
          fullWidth
          color="primary"
          component={Link}
          href={card.navbarButton.link}
          onClick={onButtonClick}
          {...getIconProps(card.navbarButton)}
          {...(card.navbarButton.openInNewTab && {
            target: '_blank',
            rel: 'noopener noreferrer',
          })}>
          {card.navbarButton.text}
        </Button>
      )}
    </Stack>
  );
};

SideSectionCard.propTypes = {
  card: PropTypes.shape({
    cardSize: PropTypes.oneOf(['big', 'small']).isRequired,
    image: PropTypes.shape({
      data: PropTypes.object,
    }).isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string,
    navbarButton: PropTypes.shape({
      link: PropTypes.string.isRequired,
      icon: PropTypes.string,
      iconPosition: PropTypes.string,
      text: PropTypes.string,
    }),
  }),
  onButtonClick: PropTypes.func,
};

export default SideSectionCard;
