import { Grid, Stack } from '@mui/material';
import Link from 'next/link';
import PropTypes from 'prop-types';
import React from 'react';

import StrapiImage from '@/components/StrapiImage/StrapiImage';
import { trackMenuItemClick } from '@/helpers/dataLayer';

import { CategoryLink, ImageWrapper, SubLink } from './NavLinks.style';

const NavLinks = ({ menuItems, hasSideSection, sideSectionType }) => {
  if (!menuItems) {
    return null;
  }

  return (
    <Grid
      container
      item
      columnSpacing={2.5}
      rowSpacing={3}
      alignSelf="flex-start"
      xs={12}>
      {menuItems.map(item => {
        const {
          id,
          attributes: {
            title,
            link,
            navbar_links: { data: subLinks },
            image,
            openInNewTab,
          },
        } = item;

        const sanitizedPath = link ? link.replace(/\//g, '') : '';
        const marketingClassname = `mrkt-head_kat_${sanitizedPath}`;

        return (
          <Grid
            item
            xs={hasSideSection ? (sideSectionType === 'big' ? 6 : 4) : 2.4}
            lg={hasSideSection ? (sideSectionType === 'big' ? 4 : 3) : 2}
            key={id}>
            <Link
              href={link}
              className={marketingClassname}
              data-cy={`nav: ${title}`}
              onClick={trackMenuItemClick}
              style={{ display: 'block' }}
              {...(openInNewTab && {
                target: '_blank',
                rel: 'noopener noreferrer',
              })}>
              <ImageWrapper>
                <StrapiImage data={image.data} fill priority sizes="250px" />
              </ImageWrapper>
              <CategoryLink hasSublinks={subLinks?.length}>
                {title}
              </CategoryLink>
            </Link>
            {subLinks?.length > 0 && (
              <Stack spacing={1.5}>
                {subLinks.map(item => {
                  const {
                    id,
                    attributes: { title, link, openInNewTab },
                  } = item;
                  return (
                    <SubLink
                      key={id}
                      href={link}
                      className="mrkt-third-level-category-head"
                      data-cy={`nav: ${title}`}
                      onClick={trackMenuItemClick}
                      {...(openInNewTab && {
                        target: '_blank',
                        rel: 'noopener noreferrer',
                      })}>
                      {title}
                    </SubLink>
                  );
                })}
              </Stack>
            )}
          </Grid>
        );
      })}
    </Grid>
  );
};

NavLinks.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      attributes: PropTypes.shape({
        title: PropTypes.string.isRequired,
        link: PropTypes.string.isRequired,
        navbar_links: PropTypes.shape({
          data: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.number.isRequired,
              attributes: PropTypes.shape({
                title: PropTypes.string.isRequired,
                link: PropTypes.string.isRequired,
              }).isRequired,
            })
          ),
        }),
      }).isRequired,
    })
  ),
  hasSideSection: PropTypes.bool.isRequired,
  sideSectionType: PropTypes.string.isRequired,
};

export default NavLinks;
