import { formatPhoneToLink } from '@/helpers/utils';

const newLine = '%0d%0a';

const contactPersonPhoneMarzena = '+49 15 144 069 032';
const contactPersonPhoneMarta = '+49 15 116 367 384';

const contactPersonEmail = 'rent@klickrent.de';
const emailSubject = 'Rental Request / Zapytanie ofertowe';
const emailBody = `Good day,${newLine}please make me an offer as follows:${newLine}${newLine}Rental object: "Construction machine/container..."${newLine}Rental period: “from - to”${newLine}Location: “Address”${newLine}Company: ${newLine}Contact details:${newLine}${newLine}————————————————————${newLine}${newLine}Witam,${newLine}proszę o ofertę jak poniżej:${newLine}${newLine}Obiekt do wynajęcia: "Maszyna budowlana/kontener..."${newLine}Okres wynajmu: „od – do”${newLine}Adres lokalizacji:${newLine}Nazwa Firmy:${newLine}Dane kontaktowe:${newLine}${newLine}`;
const contactPersonEmailHref = `mailto:${contactPersonEmail}?subject=${emailSubject}&body=${emailBody}`;

const becomePartnerSubject = 'Become a partner / zostań partnerem';
const becomePartnerBody = `Become a partner – introduce your company, send us your price list and/or portfolio.${newLine}${newLine}Zostań partnerem – przedstaw nam swoją firmę, prześlij nam swój cennik i/lub portfolio.${newLine}${newLine}`;
const becomePartnerEmailHref = `mailto:${contactPersonEmail}?subject=${becomePartnerSubject}&body=${becomePartnerBody}`;

export const contactData = {
  contactPersonEmail,
  contactPersonEmailHref,
  becomePartnerEmailHref,
  contactPersonPhoneMarzena: '+49 15 144 069 032',
  contactPersonPhoneHrefMarzena: formatPhoneToLink(contactPersonPhoneMarzena),
  contactPersonPhoneMarta: '+49 15 116 367 384',
  contactPersonPhoneHrefMarta: formatPhoneToLink(contactPersonPhoneMarta),
};

export const internationalPagesPaths = ['/intl', '/pl'];
