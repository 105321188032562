import { styled } from '@mui/material/styles';

export const Wrapper = styled('div')(() => ({
  width: '100vw',
  position: 'relative',
  flex: 1,
}));

const levelsLeftOffset = {
  1: 0,
  2: '-100vw',
  3: '-200vw',
};

export const Levels = styled('div', {
  shouldForwardProp: prop => prop !== 'activeLevel',
})(({ activeLevel }) => ({
  display: 'flex',
  transition: 'left 0.3s linear',
  position: 'absolute',
  width: '100vw',
  height: '100%',
  left: levelsLeftOffset[activeLevel],
}));

export const Level = styled('div')(() => ({
  width: '100vw',
  minWidth: '100vw',
}));

export const Links = styled('div', {
  shouldForwardProp: prop => prop !== 'active',
})(({ active, theme }) => ({
  display: active ? 'block' : 'none',
  marginBottom: theme.spacing(6.25),
}));

export const BackLink = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(3, 2),
}));
