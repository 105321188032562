import { styled } from '@mui/material/styles';

import ButtonBase from '@/components/ui/Button/Button';

export const SideSectionImage = styled('div', {
  shouldForwardProp: prop => prop !== 'cardSize',
})(({ theme, cardSize }) => ({
  width: '100%',
  height: cardSize === 'big' ? 235 : 150,
  position: 'relative',
  img: {
    borderRadius: 4,
    objectFit: 'cover',
    objectPosition: 'center',
  },
  marginBottom: theme.spacing(1),
}));

export const Button = styled(ButtonBase)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(13),
  textTransform: 'uppercase',
  marginTop: theme.spacing(2),
}));

export const CardTitle = styled('div')(({ theme }) => ({
  fontFamily: theme.typography.fontFamilyRedesign,
  fontSize: theme.typography.pxToRem(14),
  fontWeight: theme.typography.fontWeightSemiBold,
  letterSpacing: '0.32px',
  lineHeight: 1.5,
  color: theme.palette.primary.dark,
  marginBottom: theme.spacing(0.5),
}));

export const CardText = styled('div')(({ theme }) => ({
  '& *': {
    ...theme.typography.body2Redesign,
    letterSpacing: '0.17px',
    padding: 0,
    margin: 0,
  },

  a: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
  },
}));
