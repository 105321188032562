import { Badge as MuiBadge, badgeClasses, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Wrapper = styled('div')(() => ({
  height: 24,
  width: 24,
  cursor: 'pointer',
  position: 'relative',
  top: -1,
}));

export const Badge = styled(MuiBadge)(({ theme }) => ({
  [`& .${badgeClasses.badge}`]: {
    top: 4,
    right: 3,
    height: 16,
    minWidth: 16,
    padding: '0 3px',
    fontWeight: theme.typography.fontWeightBlack,
  },
}));

export const BasketText = styled(Typography)(({ theme }) => ({
  fontFamily: theme.typography.fontFamilyRedesign,
  fontSize: theme.typography.pxToRem(12),
  fontWeight: theme.typography.fontWeightRegular,
  lineHeight: 1,
  letterSpacing: '0.263px',
  cursor: 'pointer',
}));
