import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Banner = styled(Typography)(({ theme }) => ({
  width: '100%',
  minHeight: 46,
  background: theme.palette.secondary.light,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  transition: 'all 0.3s linear',
}));
