export const sortByName = links =>
  links.sort((a, b) => (a.name > b.name ? 1 : -1));

export const sortByNameAndChildren = links =>
  links.sort((a, b) => {
    if (!a.children && b.children) {
      return 1;
    }
    if (a.children && !b.children) {
      return -1;
    }
    return a.name > b.name ? 1 : -1;
  });
