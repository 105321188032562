import { Typography as MuiTypography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ContentWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(3, 0, 4, 0),
  maxHeight: '80vh',
  overflowY: 'auto',
}));

export const ItemWrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.grayscale[100],
  padding: theme.spacing(1, 1, 2, 1),
  fontSize: theme.typography.pxToRem(14),
  borderRadius: theme.shape.borderRadius,
}));

export const ItemName = styled(MuiTypography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
}));
