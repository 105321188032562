import { useMediaQuery } from '@mui/material';

export const useMobileMediaQuery = (options = {}) => {
  const isMobileMediaQuery = useMediaQuery(
    theme => theme.breakpoints.down('md'),
    options
  );

  return isMobileMediaQuery;
};
