import DownArrow from '@mui/icons-material/KeyboardArrowDown';
import { Stack, Typography } from '@mui/material';
import Link from 'next/link';
import PropTypes from 'prop-types';
import React from 'react';

import PageMargins from '@/components/ui/PageMargins/PageMargins';
import { trackMenuItemClick } from '@/helpers/dataLayer';

import { NavbarItem, NavbarWrapper } from './Navbar.style';

function Navbar({
  handleMouseEnter,
  handleOnClick,
  handleClose,
  navbarLinks,
  menuItems,
}) {
  const renderNavbarLinks = () =>
    navbarLinks.map(navbarLink => {
      const {
        id,
        attributes: { link, title, openInNewTab },
      } = navbarLink;
      return (
        <NavbarItem
          component={Link}
          href={link}
          key={id}
          direction="row"
          alignItems="center"
          data-cy={`nav: ${title}`}
          onMouseEnter={handleClose}
          onClick={trackMenuItemClick}
          {...(openInNewTab && {
            target: '_blank',
            rel: 'noopener noreferrer',
          })}>
          <Typography
            variant="body1Redesign"
            component="span"
            lineHeight={1}
            letterSpacing="0.32px">
            {title}
          </Typography>
        </NavbarItem>
      );
    });

  const renderMenuItems = () =>
    menuItems.map(menuItem => {
      const {
        id,
        attributes: { title },
      } = menuItem;
      return (
        <NavbarItem
          component="button"
          key={id}
          direction="row"
          alignItems="center"
          data-cy={`nav: ${title}`}
          onMouseEnter={() => handleMouseEnter(menuItem)}
          onClick={() => handleOnClick(menuItem)}>
          <Typography
            variant="body1Redesign"
            component="span"
            lineHeight={1}
            letterSpacing="0.32px">
            {title}
          </Typography>
          <DownArrow sx={{ fontSize: '20px' }} />
        </NavbarItem>
      );
    });

  return (
    <NavbarWrapper bgcolor="common.white">
      <PageMargins>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-end"
          sx={{
            height: 63,
          }}
          spacing={2}>
          {renderMenuItems()}
          {renderNavbarLinks()}
        </Stack>
      </PageMargins>
    </NavbarWrapper>
  );
}

Navbar.propTypes = {
  handleMouseEnter: PropTypes.func.isRequired,
  handleOnClick: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      attributes: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }).isRequired,
    })
  ),
  navbarLinks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      attributes: PropTypes.shape({
        link: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
      }).isRequired,
    })
  ).isRequired,
};

export default Navbar;
