import MuiPaper from '@mui/material/Paper';
import PropTypes from 'prop-types';
import React from 'react';

const Paper = React.forwardRef(({ children, elevation, ...props }, ref) => (
  <MuiPaper elevation={elevation} ref={ref} {...props}>
    {children}
  </MuiPaper>
));

Paper.displayName = 'Paper';

Paper.propTypes = {
  /** Displayed content. */
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
  /** Elevation level. */
  elevation: PropTypes.oneOf([0, 1, 2]),
};

export default Paper;
