import { TextField } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const Input = React.forwardRef(
  (
    {
      label,
      inputMode,
      inputProps,
      readOnly,
      autoComplete,
      helperText,
      InputProps,
      tabIndex,
      'data-cy': dataCy,
      ...props
    },
    ref
  ) => (
    <TextField
      variant="filled"
      size="small"
      label={label}
      hiddenLabel={!label}
      helperText={helperText}
      inputProps={{
        inputMode,
        'data-cy': dataCy || `input: ${label}`,
        tabIndex,
        ...inputProps,
        autoComplete,
      }}
      InputProps={{
        readOnly,
        ...InputProps,
      }}
      inputRef={ref}
      FormHelperTextProps={{ 'data-cy': `helper-text: ${label}` }}
      {...props}
    />
  )
);

// More info about available props https://mui.com/api/text-field/
Input.propTypes = {
  /** Name of the input. */
  name: PropTypes.string,
  /** CSS theme */
  className: PropTypes.string,
  /** Showed label. */
  label: PropTypes.string,
  /** Helper text/Warning message */
  helperText: PropTypes.string,
  /** Are data invalid */
  error: PropTypes.bool,
  /** Should be disabled */
  disabled: PropTypes.bool,
  /** Given value */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /** Callback executed on change */
  onChange: PropTypes.func,
  /** Callback executed on blur */
  onBlur: PropTypes.func,
  /** Callback executed on focus */
  onFocus: PropTypes.func,
  /** Tab index passed to input. */
  tabIndex: PropTypes.number,
  /** Should input be read only. */
  readOnly: PropTypes.bool,
  /** Input mode for mobile devices */
  inputMode: PropTypes.oneOf([
    'none',
    'text',
    'decimal',
    'numeric',
    'tel',
    'search',
    'email',
    'url',
  ]),
  /** Required */
  required: PropTypes.bool,
  autoComplete: PropTypes.string,
  /** Cypress identifier */
  'data-cy': PropTypes.string,
  /** Attributes applied to the input element.*/
  inputProps: PropTypes.object,
  /** Props applied to the Input element.  */
  InputProps: PropTypes.object,
};

Input.defaultProps = {
  name: '',
  className: '',
  label: '',
  helperText: '',
  error: false,
  disabled: false,
  value: undefined,
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
  tabIndex: null,
  readOnly: false,
  inputMode: 'text',
  required: false,
  autoComplete: 'on',
  'data-cy': '',
  inputProps: {},
  InputProps: {},
};

Input.displayName = 'Input';

export default Input;
