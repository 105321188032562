import MuiHorizontalRuleIcon from '@mui/icons-material/HorizontalRule';
import { Checkbox as MuiCheckbox } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import { CheckboxCheckedIcon, CheckboxIcon, CheckIcon } from './Checkbox.style';

const Checkbox = ({
  name,
  size,
  disabled,
  light,
  error,
  'data-cy': dataCy,
  tabIndex,
  indeterminate,
  inputProps,
  checked,
  onChange,
  ...props
}) => {
  return (
    <MuiCheckbox
      size={size}
      disableRipple
      checkedIcon={
        <CheckboxCheckedIcon disabled={disabled} light={light}>
          <CheckIcon light={light} />
        </CheckboxCheckedIcon>
      }
      indeterminateIcon={
        <CheckboxCheckedIcon disabled={disabled} light={light}>
          <CheckIcon component={MuiHorizontalRuleIcon} light={light} />
        </CheckboxCheckedIcon>
      }
      icon={<CheckboxIcon error={error} light={light} />}
      data-cy={dataCy}
      tabIndex={tabIndex}
      inputProps={{
        'aria-invalid': error,
        name: name,
        ...inputProps,
      }}
      indeterminate={indeterminate}
      disabled={disabled}
      checked={checked}
      onChange={onChange}
      {...props}
    />
  );
};

Checkbox.propTypes = {
  name: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium']),
  disabled: PropTypes.bool,
  light: PropTypes.bool,
  error: PropTypes.bool,
  'data-cy': PropTypes.string,
  tabIndex: PropTypes.number,
  indeterminate: PropTypes.bool,
  inputProps: PropTypes.object,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

Checkbox.defaultProps = {
  name: '',
  size: 'medium',
  disabled: false,
  light: false,
  error: false,
  'data-cy': '',
  tabIndex: null,
  indeterminate: false,
  inputProps: {},
  checked: false,
  onChange: () => {},
};

export default Checkbox;
