import MuiCheckIcon from '@mui/icons-material/Check';
import { styled } from '@mui/material/styles';

export const CheckboxIcon = styled('span', {
  shouldForwardProp: prop => !['error', 'light'].includes(prop),
})(({ theme, error, light }) => ({
  borderRadius: 4,
  width: 16,
  height: 16,
  backgroundColor: 'transparent',
  border: `2px solid ${
    light ? theme.palette.common.white : theme.palette.grayscale[600]
  }`,
  ...(error && { borderColor: theme.palette.error.main }),

  '.Mui-focusVisible &': {
    outline: `2px solid ${theme.palette.common.lightBlue}`,
  },
  'input:hover ~ &, input:focus ~ &': {
    outline: `2px solid ${theme.palette.common.lightBlue}`,
  },
  'input:disabled ~ &': {
    borderColor: theme.palette.grayscale[600],
    pointerEvents: 'none',
    outline: 'none',
  },
}));

export const CheckboxCheckedIcon = styled(CheckboxIcon, {
  shouldForwardProp: prop => prop !== 'light',
})(({ theme, disabled, light }) => ({
  backgroundColor: disabled
    ? theme.palette.grayscale[700]
    : light
    ? theme.palette.common.white
    : theme.palette.primary.main,
  border: 'none',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const CheckIcon = styled(MuiCheckIcon, {
  shouldForwardProp: prop => prop !== 'light',
})(({ theme, light }) => ({
  color: light ? theme.palette.primary.main : theme.palette.common.white,
  fontSize: 14,
}));
