import PropTypes from 'prop-types';
import React from 'react';

import { BottomLinkStyled } from './BottomLink.style';

const BottomLink = ({ bottomLink, onClick }) => {
  return (
    <BottomLinkStyled
      onClick={onClick}
      dangerouslySetInnerHTML={{
        __html: bottomLink,
      }}
    />
  );
};

BottomLink.propTypes = {
  bottomLink: PropTypes.string,
  onClick: PropTypes.func,
};

export default BottomLink;
