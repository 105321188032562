import { Box, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Collapse = styled(Box, {
  shouldForwardProp: prop => !['active', 'navbarHeight'].includes(prop),
})(({ active, navbarHeight, theme }) => ({
  position: 'absolute',
  top: '100%',
  left: 0,
  right: 0,
  zIndex: -1,
  maxHeight: `calc((100vh - ${navbarHeight}px) * 0.9)`,
  display: 'grid',
  gridTemplateRows: active ? '1fr' : '0fr',
  transition: `${theme.transitions.create(['grid-template-rows'], {
    duration: theme.transitions.duration.standard,
  })}`,
}));

export const MenuWrapper = styled(Stack, {
  shouldForwardProp: prop => prop !== 'sideSection',
})(({ sideSection, theme }) => ({
  background: sideSection
    ? `linear-gradient(to right, ${theme.palette.common.white} 50%, ${theme.palette.primaryColorShades.p4} 50%)`
    : theme.palette.common.white,
  overflowY: 'scroll',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
}));
