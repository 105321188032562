import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import Logo from '@/components/ui/Logo/Logo';

import { Wrapper } from './HeaderLogo.style';

const HeaderLogo = ({ 'data-cy': dataCy, className, onClick }) => {
  return (
    <Wrapper
      className={cx('mrkt-head_logo', className)}
      onClick={onClick}
      data-cy={dataCy}
      href="/">
      <Logo />
    </Wrapper>
  );
};

HeaderLogo.propTypes = {
  'data-cy': PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
};
HeaderLogo.defaultProps = {
  'data-cy': '',
  className: '',
  onClick: () => {},
};

export default HeaderLogo;
