import { Stack } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import SideSectionCard from '@/components/PageHeaderNew/SideSectionCard/SideSectionCard';

const SideSection = ({ hasSideSection, sideSectionCards }) => {
  return (
    hasSideSection && (
      <Stack
        direction="column"
        bgcolor="primaryColorShades.p4"
        pl={7}
        pt={3}
        pb={7}
        spacing={6}
        sx={{
          height: '100%',
        }}>
        {sideSectionCards?.map((card, index) => (
          <SideSectionCard card={card} key={index} />
        ))}
      </Stack>
    )
  );
};

SideSection.propTypes = {
  hasSideSection: PropTypes.bool.isRequired,
  sideSectionCards: PropTypes.arrayOf(
    PropTypes.shape({
      cardSize: PropTypes.oneOf(['big', 'small']).isRequired,
      image: PropTypes.shape({
        data: PropTypes.object,
      }).isRequired,
      title: PropTypes.string.isRequired,
      content: PropTypes.string,
      navbarButton: PropTypes.shape({
        link: PropTypes.string.isRequired,
        icon: PropTypes.string,
        iconPosition: PropTypes.string,
        text: PropTypes.string,
      }),
    })
  ),
};

export default SideSection;
