import { styled } from '@mui/material/styles';

export const Partner = styled('li')({
  position: 'relative',
  listStyle: 'none',

  img: {
    objectFit: 'contain',
  },
});

export const Title = styled('div')(({ theme }) => ({
  color: theme.palette.grayscale[500],
  fontFamily: theme.typography.fontFamilyRedesign,
  fontSize: theme.typography.pxToRem(10),
  textTransform: 'uppercase',
  fontWeight: theme.typography.fontWeightSemiBold,
  letterSpacing: '0.32px',
}));
