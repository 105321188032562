import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import Button from '@/components/ui/Button/Button';
import InputBase from '@/components/ui/Input/Input';

export const SubmitButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    boxShadow: 'none',
    maxHeight: 48,
  },

  [theme.breakpoints.down('md')]: {
    width: '100%',
    marginTop: theme.spacing(3),
    height: 48,
  },
}));

export const SuccessMessageWrapper = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(12.5),
  [theme.breakpoints.down('md')]: {
    marginLeft: 0,
  },
}));

export const NewsletterPrivacyWrapper = styled('div')(({ theme }) => ({
  margin: theme.spacing(2, 0),
}));

export const LinkItem = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.white,
  textDecoration: 'underline',
}));

export const Input = styled(InputBase)(({ theme }) => ({
  flex: 1,
  '& .MuiFilledInput-root, & .MuiFilledInput-root:hover': {
    background: `${theme.palette.grayscale[100]} !important`,
  },

  [theme.breakpoints.up('md')]: {
    '& .MuiFilledInput-root': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
    },
  },
}));

export const InputSection = styled('div')(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));
