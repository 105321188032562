import MUILoadingButton from '@mui/lab/LoadingButton';
import PropTypes from 'prop-types';
import React from 'react';

const LoadingButton = React.forwardRef(
  ({ children, variant, color, 'data-cy': dataCy, ...props }, ref) => {
    return (
      <MUILoadingButton
        ref={ref}
        data-cy={
          dataCy ||
          (typeof children === 'string' ? `loadingButton: ${children}` : null)
        }
        variant={variant}
        color={color}
        {...props}>
        {children}
      </MUILoadingButton>
    );
  }
);

LoadingButton.displayName = 'LoadingButton';

LoadingButton.propTypes = {
  /** Displayed content. */
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
  /** Cypress hook. */
  'data-cy': PropTypes.string,
  variant: PropTypes.oneOfType([
    PropTypes.oneOf(['contained', 'outlined', 'text']),
    PropTypes.string,
  ]),
};

LoadingButton.defaultProps = {
  'data-cy': null,
  variant: 'contained',
  color: 'secondary',
};

export default LoadingButton;
