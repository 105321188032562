import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import SvgIcon from '@/components/ui/SvgIcon/SvgIcon';

export const Wrapper = styled(Typography)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const Icon = styled(SvgIcon)(({ theme }) => ({
  color: theme.palette.common.blue,
  position: 'relative',
  marginRight: 4,
}));

export const IconNewHeader = styled(SvgIcon)(({ theme }) => ({
  color: theme.palette.common.white,
  position: 'relative',
}));
