import { DialogContent, DialogTitle, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import { Button } from '../DialogContent/DialogContent.style';
import { ImageWrapper } from './StatusContent.style';

const StatusContent = ({
  title,
  mainText,
  onButtonClick,
  buttonText,
  image,
}) => {
  return (
    <>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Stack spacing={3} alignItems="center" mb={2}>
          <Typography>{mainText}</Typography>
          <ImageWrapper>{image}</ImageWrapper>
          <Button size="large" fullWidth onClick={onButtonClick}>
            {buttonText}
          </Button>
        </Stack>
      </DialogContent>
    </>
  );
};

StatusContent.propTypes = {
  title: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  image: PropTypes.element.isRequired,
  mainText: PropTypes.node.isRequired,
};

export default StatusContent;
