import PropTypes from 'prop-types';

import { Backdrop, Dialog as StyledDialog } from './Dialog.style';

/**
 * Render content over the page and prevent body scroll.
 */
const Dialog = props => {
  const {
    open,
    visiblePhone,
    mobileMenu,
    headerHeight,
    children,
    ...restProps
  } = props;

  return (
    <StyledDialog
      id="overlayWrapper"
      open={open}
      components={{ Backdrop: Backdrop }}
      visiblePhone={visiblePhone}
      headerHeight={headerHeight}
      componentsProps={{
        backdrop: { visiblePhone },
      }}
      mobileMenu={mobileMenu}
      {...restProps}>
      {children}
    </StyledDialog>
  );
};

export default Dialog;

Dialog.propTypes = {
  /** Content of overlay. */
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
  /** Should menu be visible in mobile. */
  mobileMenu: PropTypes.bool,
  /** Should phone number be visible in mobile. */
  visiblePhone: PropTypes.bool,
};

Dialog.defaultProps = {
  mobileMenu: true,
  visiblePhone: false,
};
