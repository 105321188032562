import { Tooltip as MuiTooltip, tooltipClasses } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Tooltip = styled(({ className, ...props }) => (
  <MuiTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  minWidth: 300,

  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
    boxShadow: theme.boxShadow.elevation1,
    marginTop: theme.spacing(2.5),
    padding: 0,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
    width: '2em',
    height: '1.42em',
    marginTop: '-1.40em !important',
    '&:before': {
      boxShadow: theme.boxShadow.elevation1,
    },
  },
}));
