import { Typography } from '@mui/material';
import Link from 'next/link';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import api from '@/helpers/api';
import { trackRequestNewsletter } from '@/helpers/dataLayer';
import * as i18n from '@/helpers/i18n';
import { isEmailValid } from '@/helpers/validators';

import {
  Input,
  InputSection,
  LinkItem,
  NewsletterPrivacyWrapper,
  SubmitButton,
  SuccessMessageWrapper,
} from './Newsletter.style';

/**
 * Newsletter component.
 */
class Newsletter extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitted: false,
      email: '',
      error: false,
      label: this.props.intl.formatMessage({
        id: 'footer_newsletter_input',
      }),
    };
  }

  setErrorMessage = msg => {
    this.setState({
      error: true,
      label: this.props.intl.formatMessage({ id: msg }),
    });
  };

  handleClick = () => {
    if (this.state.email && isEmailValid(this.state.email)) {
      const isSubmitted = this.subscribeNewsletter();
      this.setState(() => ({ isSubmitted }));
      trackRequestNewsletter();
    } else if (!this.state.email) {
      this.setErrorMessage('footer_newsletter_error');
    } else if (this.state.email && !isEmailValid(this.state.email)) {
      this.setState({ email: '' }, () => {
        this.setErrorMessage('contact_details_email_error');
      });
    }
  };

  handleTextInput = e => {
    this.clearWarnings();
    this.setState(() => ({ email: e.target.value }));
  };

  clearWarnings = () => {
    this.setState(() => ({
      error: false,
      label: this.props.intl.formatMessage({
        id: 'footer_newsletter_input',
      }),
    }));
  };

  async subscribeNewsletter() {
    if (!this.state.email) {
      return false;
    }

    await api.subscribeNewsletter(this.state.email);

    return true;
  }

  render() {
    const newsletterForm = (
      <div>
        <Typography variant="body2" mb={2} color="common.white">
          <FormattedMessage id="footer_newsletter_head" />
        </Typography>
        <InputSection>
          <Input
            id="newsletterEmail"
            value={this.state.email}
            onChange={this.handleTextInput}
            name="newsletterEmail"
            label={this.state.label}
            error={this.state.error}
            inputMode="email"
          />
          <SubmitButton size="large" onClick={this.handleClick}>
            <FormattedMessage id="footer_newsletter_CTA" />
          </SubmitButton>
        </InputSection>
        <NewsletterPrivacyWrapper>
          <Typography variant="label" color="common.white">
            <FormattedMessage
              id="footer_newsletter_explanation"
              values={{
                ...i18n.elements,
                a: chunks => (
                  <LinkItem
                    component={Link}
                    href="https://www.klickrent.de/data-privacy"
                    variant="label"
                    target="_blank"
                    rel="noopener noreferrer">
                    {chunks}
                  </LinkItem>
                ),
              }}
            />
          </Typography>
        </NewsletterPrivacyWrapper>
      </div>
    );

    const successMessage = (
      <SuccessMessageWrapper>
        <FormattedMessage id="footer_newsletter_success1">
          {txt => (
            <Typography variant="body2" color="common.white" mb={4}>
              {txt}
            </Typography>
          )}
        </FormattedMessage>
        <FormattedMessage
          id="footer_newsletter_success2"
          values={{
            ...i18n.elements,
            email: (
              <Typography component="span" variant="body1">
                {this.state.email}
              </Typography>
            ),
          }}>
          {txt => (
            <Typography variant="body2" color="common.white" mb={4}>
              {txt}
            </Typography>
          )}
        </FormattedMessage>
      </SuccessMessageWrapper>
    );

    return this.state.isSubmitted ? successMessage : newsletterForm;
  }
}

Newsletter.propTypes = {
  /** API access to translations */
  intl: PropTypes.object.isRequired,
};

export default injectIntl(Newsletter);
