import { Box } from '@mui/material';
import Link from 'next/link';
import PropTypes from 'prop-types';
import React from 'react';

import {
  sortByName,
  sortByNameAndChildren,
} from '@/helpers/sortNavigationLinks';

import {
  GridWrapper,
  SecondaryLink,
  SecondWrapper,
  TertiaryLink,
  Wrapper,
} from './NestedNavigation.style';

/**
 * Desktop only.
 * Second and third levels of navigation.
 */

const NestedNavigation = ({ links, onLinkClick }) => {
  const renderThird = ({ name, href }) => (
    <Box mt={2} key={`${name}-${href}`}>
      <TertiaryLink
        onClick={onLinkClick}
        component={Link}
        href={href}
        variant="body1"
        className="mrkt-third-level-category-head"
        data-cy={`nav: ${name}`}>
        {name}
      </TertiaryLink>
    </Box>
  );

  const renderSecond = ({ name, href, children }) => {
    const sanitizedPath = href ? href.replace(/\//g, '') : '';
    const marketingClassname = `mrkt-head_kat_${sanitizedPath}`;

    return (
      <SecondWrapper key={`${name}-${href}`}>
        <SecondaryLink
          onClick={onLinkClick}
          component={Link}
          href={href}
          variant="body2"
          className={marketingClassname}
          data-cy={`nav: ${name}`}>
          {name}
        </SecondaryLink>
        {children && sortByName(children).map(renderThird)}
      </SecondWrapper>
    );
  };

  return (
    <Wrapper>
      <GridWrapper>
        {sortByNameAndChildren(links).map(renderSecond)}
      </GridWrapper>
    </Wrapper>
  );
};

NestedNavigation.propTypes = {
  /** Menu structure. */
  links: PropTypes.array.isRequired,
};

export default NestedNavigation;
