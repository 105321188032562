import { styled } from '@mui/material/styles';

export const BottomLinkStyled = styled('div')(({ theme }) => ({
  fontFamily: theme.typography.fontFamilyRedesign,
  fontSize: theme.typography.pxToRem(12),
  fontWeight: theme.typography.fontWeightSemiBold,
  letterSpacing: '0.32px',
  lineHeight: 1.2,

  '& *': {
    padding: 0,
    margin: 0,
    color: theme.palette.grayscale[500],
  },

  a: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
  },
}));
