import React from 'react';

import getConfig from '@/configs/global';

import { Image as NextImage } from './StrapiImage.style';

const config = getConfig();
const { publicRuntimeConfig } = config;

const { CMS_URL } = publicRuntimeConfig;

const cloudfrontImage = url =>
  typeof url === 'string'
    ? url.includes('d32q53fvpczhua.cloudfront.net')
    : false;

const cloudfrontImageLoader = ({ src, width }) => {
  const format = width > 2048 ? 'webp' : 'auto';

  return `${src}?format=${format}&width=${width}`;
};

export default function Image({ data, ...restProps }) {
  if (!data) return null;

  const { attributes } = data;
  const absoluteUrl = new URL(attributes.url, CMS_URL).toString();

  return (
    <NextImage
      src={absoluteUrl}
      alt={attributes.alternativeText || attributes.caption || ''}
      {...(!restProps?.fill && {
        height: attributes.height,
        width: attributes.width,
      })}
      {...(cloudfrontImage(absoluteUrl) && {
        loader: cloudfrontImageLoader,
      })}
      {...restProps}
    />
  );
}
