import { styled } from '@mui/material/styles';

export const CountryPrefix = styled('div')(({ theme }) => ({
  minWidth: 50,
  marginRight: theme.spacing(4),
}));

export const CountryName = styled('div')(() => ({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
}));
