import PropTypes from 'prop-types';
import React from 'react';

import BasketContent from './BasketContent';
import { Tooltip } from './BasketPreview.style';
import EmptyBasketContent from './EmptyBasketContent';

const BasketPreview = ({
  children,
  open,
  basketEmpty,
  onClose,
  onOpen,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <Tooltip
      open={open}
      title={
        <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
          {basketEmpty ? <EmptyBasketContent /> : <BasketContent />}
        </div>
      }
      arrow
      leaveDelay={1000}
      onClose={onClose}
      onOpen={onOpen}
      disableTouchListener
      componentsProps={{ popper: { 'data-cy': 'basketPreview' } }}>
      {children}
    </Tooltip>
  );
};

BasketPreview.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  open: PropTypes.bool,
  basketEmpty: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onOpen: PropTypes.func.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
};

BasketPreview.defaultProps = {
  open: false,
  basketEmpty: true,
};

export default BasketPreview;
