import MuiClickAwayListener from '@mui/material/ClickAwayListener';
import PropTypes from 'prop-types';

/**
 * Detect click outside.
 */
const ClickAwayListener = ({ children, onClickAway }) => {
  return (
    <MuiClickAwayListener mouseEvent="onMouseDown" onClickAway={onClickAway}>
      {children}
    </MuiClickAwayListener>
  );
};

export default ClickAwayListener;

ClickAwayListener.propTypes = {
  /** Wrapped content. */
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.node,
  ]).isRequired,
  /** Listener for click outside wrapped content. */
  onClickAway: PropTypes.func.isRequired,
};
