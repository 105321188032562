import { Box, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

export const NavbarWrapper = styled(Box)(() => ({
  boxShadow: '0px 2px 20px 0px rgba(0, 0, 0, 0.05)',
}));

export const NavbarItem = styled(Stack, {
  shouldForwardProp: prop => prop !== 'active',
})(({ theme }) => ({
  cursor: 'pointer',
  paddingBottom: theme.spacing(2),
  background: 'none',
  border: 'none',
  borderBottom: '2px solid transparent',
  transition: 'border-color 0.2s',

  '&:hover': {
    borderColor: theme.palette.primary.main,
    outline: 'none',
  },
}));
