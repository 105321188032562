import { Box, IconButton, Stack, Typography } from '@mui/material';
import { isSameDay } from 'date-fns';
import Link from 'next/link';
import React from 'react';

import Button from '@/components/ui/Button/Button';
import InputCounter from '@/components/ui/InputCounter/InputCounter';
import SvgIcon from '@/components/ui/SvgIcon/SvgIcon';
import Tooltip from '@/components/ui/Tooltip/Tooltip';
import { formatDate } from '@/helpers/utils';
import { useBasketContext } from '@/hooks/useBasketContext';
import { useBasketPreviewContext } from '@/hooks/useBasketPreviewContext';
import useFormatMessage from '@/hooks/useFormatMessage';

import { ContentWrapper, ItemName, ItemWrapper } from './BasketContent.style';

const BasketContent = () => {
  const {
    requestsInBasket,
    updateRequestInBasket,
    removeRequestFromBasket,
  } = useBasketContext();
  const { closeBasketPreview } = useBasketPreviewContext();
  const formatMessage = useFormatMessage();

  const handleItemCountChange = (value, request) => {
    const updatedAccessories = request.accessories.map(accessory => {
      const invisibleOrIncludedAccessory =
        accessory.itemData.invisible || accessory.itemData.included;

      return {
        ...accessory,
        count: invisibleOrIncludedAccessory ? value : accessory.count,
      };
    });

    updateRequestInBasket(request.id, {
      count: value,
      accessories: updatedAccessories,
    });
  };

  return (
    <ContentWrapper>
      <Stack spacing={1} mx={1}>
        {requestsInBasket.map(request => (
          <ItemWrapper key={request.id} data-cy="basketPreviewItem">
            <ItemName variant="body2">{request.rentalObject.name}</ItemName>
            {request.dateFrom && request.dateTo ? (
              <Typography variant="caption" component="p" mb={1}>
                {`${formatDate(request.dateFrom)} - ${formatDate(
                  request.dateTo
                )}`}
              </Typography>
            ) : (
              <Typography variant="caption" color="error" component="p" mb={1}>
                {formatMessage('basketOverlay_noDate')}
              </Typography>
            )}
            {request.rentalHours &&
              isSameDay(request.dateFrom, request.dateTo) && (
                <Typography variant="caption" component="p" mb={1}>
                  {`${formatMessage('basketOverlay_hours_label')}: ${
                    request.rentalHours
                  }`}
                </Typography>
              )}
            <Stack spacing={1} direction="row" mt={1.5}>
              <InputCounter
                value={request.count}
                min={1}
                onChange={value => handleItemCountChange(value, request)}
              />
              <Tooltip title={formatMessage('basketOverlay_deleteHelper')}>
                <IconButton
                  aria-label="delete"
                  onClick={() => removeRequestFromBasket(request.id)}
                  data-cy="button: deleteItem">
                  <SvgIcon name="trashcan" color="primary" />
                </IconButton>
              </Tooltip>
            </Stack>
          </ItemWrapper>
        ))}
      </Stack>
      <Box mx={2} mt={6.5}>
        <Button
          size="large"
          fullWidth
          startIcon={<SvgIcon name="shoppingCart" sx={{ mb: '3px' }} />}
          component={Link}
          href="/basket"
          data-cy="button: goToBasket"
          onClick={closeBasketPreview}>
          {formatMessage('basketOverlay_CTA')}
        </Button>
      </Box>
    </ContentWrapper>
  );
};

export default BasketContent;
