import { Stack } from '@mui/material';
import Link from 'next/link';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { sortByName } from '@/helpers/sortNavigationLinks';

import { MainLink, Wrapper } from './Navigation.style';
import NestedNavigation from './NestedNavigation';

/**
 * Desktop only.
 * First level of navigation.
 */

const Navigation = ({ links, onMouseEnter, onMouseLeave, onLinkClick }) => {
  const [activeLink, setActiveLink] = useState(null);

  const renderLink = link => {
    const { name, href = '' } = link;
    const sanitizedPath = href ? href.replace(/\//g, '') : '';
    const marketingClassname = `mrkt-head_kat_${sanitizedPath}`;

    return (
      <MainLink
        component={Link}
        href={href}
        key={`${name} - ${href}`}
        variant="body1"
        active={activeLink && activeLink.href === href}
        data-cy={`nav: ${name}`}
        onMouseEnter={() => setActiveLink(link)}
        className={marketingClassname}
        onClick={onLinkClick}>
        {name}
      </MainLink>
    );
  };

  return (
    <Wrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        {sortByName(links).map(renderLink)}
      </Stack>
      {activeLink && activeLink.children && (
        <NestedNavigation
          onLinkClick={onLinkClick}
          links={activeLink.children}
        />
      )}
    </Wrapper>
  );
};

Navigation.propTypes = {
  /** Menu structure. */
  links: PropTypes.array.isRequired,
  onMouseEnter: PropTypes.func.isRequired,
  onMouseLeave: PropTypes.func.isRequired,
};

export default Navigation;
