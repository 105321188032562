import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const Wrapper = styled('div')(() => ({
  width: '100vw',
  position: 'relative',
}));

const levelsLeftOffset = {
  1: 0,
  2: '-100vw',
  3: '-200vw',
};

export const Levels = styled('div', {
  shouldForwardProp: prop => prop !== 'activeLevel',
})(({ activeLevel }) => ({
  display: 'flex',
  transition: 'left 0.3s linear',
  position: 'absolute',
  width: '100vw',
  left: levelsLeftOffset[activeLevel],
}));

export const Level = styled('div')(() => ({
  width: '100vw',
  minWidth: '100vw',
}));

export const SectionHeader = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(2, 4.5),
  backgroundColor: theme.palette.grayscale[50],
}));

export const BackLink = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1.5),
  paddingRight: theme.spacing(3.5),
  backgroundColor: theme.palette.grayscale[50],
}));

export const Links = styled('div', {
  shouldForwardProp: prop => prop !== 'active',
})(({ active }) => ({
  display: active ? 'block' : 'none',
}));

export const LinkWrapper = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(2, 4.5),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));
