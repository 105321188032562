import { Divider as MuiDivider, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

import PageMargins from '@/components/ui/PageMargins/PageMargins';

export const Container = styled(PageMargins, {
  shouldForwardProp: prop => prop !== 'navigationHovered',
})(({ theme, navigationHovered }) => ({
  [theme.breakpoints.up('md')]: {
    maxHeight: 99,
    overflow: 'hidden',
    transition: 'all 0.3s, top 0ms',

    ...(navigationHovered && {
      // For animation to work max height must be set to something larger than the target height
      maxHeight: 800,
      // Delayed transistion a bit not to open menu when user is on the way to hover on the different header elements
      transitionDelay: '0.1s',
    }),
  },
}));

export const Wrapper = styled(Paper)(({ theme }) => ({
  borderRadius: 0,
  [theme.breakpoints.up('md')]: {
    width: '100%',
    position: 'fixed',
    top: 0,
    zIndex: theme.zIndex.appBar,
  },
}));

export const Header = styled('div')({
  width: '100%',
  height: 67,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  boxSizing: 'border-box',
  transition: 'all 0.3s',
});

export const Divider = styled(MuiDivider)(() => ({
  height: 24,
  // overwriting Stack spacing property
  margin: '0 4px 0 12px !important',
}));
