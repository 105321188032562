import { Box } from '@mui/material';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Backdrop } from './MegaMenu.style';
import Menu from './Menu/Menu';
import Navbar from './Navbar/Navbar';

const MegaMenu = ({ navigation, navbarHeight }) => {
  const [activeItem, setActiveItem] = useState(null);
  const [open, setIsOpen] = useState(false);
  const router = useRouter();

  const showMenu = Boolean(open && activeItem);

  const handleClose = () => setIsOpen(false);

  const handleMouseEnter = item => {
    setIsOpen(true);
    setActiveItem(item);
  };

  const handleOnClick = item => {
    if (activeItem?.id === item.id && open) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  };

  useEffect(() => {
    router.events.on('routeChangeComplete', handleClose);

    return () => {
      router.events.off('routeChangeComplete', handleClose);
    };
  }, []);

  return (
    <>
      <Box sx={{ position: 'relative' }} onMouseLeave={handleClose}>
        <Navbar
          handleClose={handleClose}
          handleMouseEnter={handleMouseEnter}
          handleOnClick={handleOnClick}
          navbarLinks={navigation?.data?.attributes?.navbar_links?.data || []}
          menuItems={navigation?.data?.attributes?.menu_items?.data || []}
        />
        <Menu
          activeItem={activeItem}
          open={showMenu}
          navbarHeight={navbarHeight}
        />
      </Box>
      <Backdrop open={showMenu} onClick={handleClose} />
    </>
  );
};

MegaMenu.propTypes = {
  navigation: PropTypes.shape({
    data: PropTypes.shape({
      attributes: PropTypes.shape({
        navbar_links: PropTypes.shape({
          data: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.number.isRequired,
              attributes: PropTypes.shape({
                link: PropTypes.string.isRequired,
                title: PropTypes.string.isRequired,
              }).isRequired,
            })
          ),
        }),
        menu_items: PropTypes.shape({
          data: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.number.isRequired,
              attributes: PropTypes.shape({
                title: PropTypes.string.isRequired,
              }).isRequired,
            })
          ),
        }),
      }),
    }),
  }),
  navbarHeight: PropTypes.number.isRequired,
};

export default MegaMenu;
