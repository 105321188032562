import { InputAdornment, Typography } from '@mui/material';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import Autocomplete from '@/components/ui/Autocomplete/Autocomplete';
import api from '@/helpers/api';
import useFormatMessage from '@/hooks/useFormatMessage';

import { Alert, InputLoader, SearchIcon } from './CompanyInput.style';

const MIN_CHAR_LIMIT_FOR_SUGGESTIONS = 3;

const getAddress = company => {
  if (company) {
    const { streetAndHouseNumber: sahn, postalCode, location } = company;
    return `${sahn}, ${postalCode} ${location}`;
  }
  return null;
};

const CompanyInput = ({
  value,
  crefo,
  label,
  onChange,
  onBlur,
  onFocus,
  inputRef,
  name,
  error,
  helperText,
  required,
  disabled,
  showAddress,
}) => {
  const formatMessage = useFormatMessage();
  const placeholderOption = {
    name: formatMessage('company_suggestion_empty_label'),
    placeholderOption: true,
  };
  const [options, setOptions] = useState([placeholderOption]);
  const [loading, setLoading] = useState(false);
  const [resetOptionsAfterLoaded, setResetOptionsAfterLoaded] = useState(false);
  const textFieldRef = useRef(null);

  const handleInputChange = value => onChange({ value, crefo: null });

  const handleCompanySelect = value =>
    onChange({ value: value.name, crefo: value });

  const resetOptions = () => {
    setResetOptionsAfterLoaded(false);
    setOptions([placeholderOption]);
  };

  const fetchOptions = useMemo(
    () =>
      debounce(async value => {
        try {
          if (value?.length >= MIN_CHAR_LIMIT_FOR_SUGGESTIONS) {
            setLoading(true);
            const { data } = await api.getCompanySuggestions(value);
            setOptions(data?.companyNameSuggestions || [placeholderOption]);
          }
        } catch (e) {
          // ignore error
        } finally {
          setLoading(false);
        }
      }, 500),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const getInputIconColor = () => {
    if (error) {
      return 'error';
    }
    if (disabled) {
      return 'disabled';
    }
    return 'primary';
  };

  const handleRenderOption = (props, option, { inputValue }) => {
    if (option.placeholderOption) {
      // eslint-disable-next-line no-unused-vars
      const { onClick, onMouseOver, onTouchStart, ...restProps } = props;

      return (
        <Typography
          {...restProps}
          key={option.name}
          fontSize="inherit"
          variant="body2"
          color="common.blue"
          component="li">
          {option.name}
        </Typography>
      );
    }

    const matches = match(option.name, inputValue);
    const parts = parse(option.name, matches);

    return (
      <li {...props} key={option.crefoNumber}>
        <div>
          <Typography fontSize="inherit" variant="body2">
            {parts.map((part, index) => (
              <Typography
                component="span"
                color={part.highlight ? 'common.blue' : 'text.primary'}
                fontSize="inherit"
                fontWeight="inherit"
                key={index}>
                {part.text}
              </Typography>
            ))}
          </Typography>
          <Typography variant="body1" color="grayscale.700" fontSize="inherit">
            {`${option.name},  ${option.streetAndHouseNumber}, ${option.postalCode} ${option.location}`}
          </Typography>
        </div>
      </li>
    );
  };

  useEffect(() => {
    if (value === '') {
      if (loading) {
        setResetOptionsAfterLoaded(true);
      } else {
        resetOptions();
      }
    } else {
      fetchOptions(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, fetchOptions]);

  useEffect(() => {
    if (resetOptionsAfterLoaded && !loading) {
      resetOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, resetOptionsAfterLoaded]);

  useEffect(() => {
    // Clean up debounced fetch after unmount
    return () => {
      fetchOptions.cancel();
    };
  }, [fetchOptions]);

  useEffect(() => {
    if (!value.trim()) return;

    const matchingOptions = options.filter(
      option => option.name === value.trim()
    );
    if (matchingOptions.length === 1) {
      handleCompanySelect(matchingOptions[0]);
      textFieldRef.current?.blur();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, options]);

  return (
    <div>
      <Autocomplete
        options={options}
        getOptionLabel={option =>
          typeof option === 'string' ? option : option.name
        }
        freeSolo
        value={value}
        label={label}
        disableClearable
        fullWidth
        filterOptions={x => x}
        TextFieldProps={{
          inputRef: e => {
            textFieldRef.current = e;

            if (inputRef) {
              if (typeof inputRef === 'function') {
                inputRef(e);
              } else {
                inputRef.current = e;
              }
            }
          },
          onFocus,
          onBlur,
          error,
          name,
          helperText,
          required,
          InputProps: {
            endAdornment: (
              <>
                <InputAdornment position="end">
                  {loading ? <InputLoader color="primary" size={18} /> : null}
                  <SearchIcon name="search" color={getInputIconColor()} />
                </InputAdornment>
              </>
            ),
          },
        }}
        onInputChange={(e, newValue) => handleInputChange(newValue)}
        onChange={(e, newValue) => handleCompanySelect(newValue)}
        data-cy="CompanyInput"
        disabled={disabled}
        renderOption={handleRenderOption}
        noOptionsText={formatMessage('company_suggestion_empty_label')}
        ListboxProps={{
          sx: {
            backgroundColor: options.some(option => option.placeholderOption)
              ? 'grayscale.100'
              : 'common.white',
          },
          'data-cy': 'CompanyInputListbox',
        }}
        openOnFocus
        autoComplete="new-password"
      />
      {crefo && showAddress && (
        <Alert data-cy="companyInfoAlert" severity="info">
          {getAddress(crefo)}
        </Alert>
      )}
    </div>
  );
};

CompanyInput.propTypes = {
  value: PropTypes.string,
  company: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      country: PropTypes.string,
      crefoNumber: PropTypes.string,
      location: PropTypes.string,
      name: PropTypes.string,
      postalCode: PropTypes.string,
      state: PropTypes.string,
      streetAndHouseNumber: PropTypes.string,
    }),
  ]),
};

CompanyInput.defaultProps = {
  value: '',
  company: null,
  showAddress: true,
};

export default CompanyInput;
