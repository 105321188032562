import { styled } from '@mui/material/styles';

export const BannerWrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.dark,
  padding: theme.spacing(0.75, 2),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: 96,
  gap: theme.spacing(0.5),
  [theme.breakpoints.up('sm')]: {
    minHeight: 62,
    padding: theme.spacing(1.25, 2),
    flexDirection: 'row',
    gap: theme.spacing(2),
  },
}));
