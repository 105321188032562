import PropTypes from 'prop-types';
import React from 'react';

import { ButtonStyled } from './Button.style';

const Button = React.forwardRef(
  (
    {
      children,
      variant,
      color,
      rounded,
      'data-cy': dataCy,
      disableElevation,
      ...props
    },
    ref
  ) => {
    return (
      <ButtonStyled
        ref={ref}
        data-cy={
          dataCy ||
          (typeof children === 'string' ? `button: ${children}` : null)
        }
        variant={variant}
        color={color}
        rounded={rounded}
        disableElevation={disableElevation}
        {...props}>
        {children}
      </ButtonStyled>
    );
  }
);

Button.displayName = 'Button';

Button.propTypes = {
  /** Displayed content. */
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
  /** Cypress hook. */
  'data-cy': PropTypes.string,
  variant: PropTypes.oneOfType([
    PropTypes.oneOf(['contained', 'outlined', 'text']),
    PropTypes.string,
  ]),
  rounded: PropTypes.bool,
};

Button.defaultProps = {
  'data-cy': null,
  variant: 'contained',
  color: 'secondary',
  rounded: false,
};

export default Button;
