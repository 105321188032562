import PropTypes from 'prop-types';
import React from 'react';

import PageHeaderLegacy from '@/components/PageHeaderLegacy/PageHeaderLegacy';
import PageHeaderNew from '@/components/PageHeaderNew/PageHeaderNew';
import getConfig from '@/configs/global';

function PageHeader(props) {
  const {
    publicRuntimeConfig: { FEAT_FLAG_NEW_PAGE_HEADER },
  } = getConfig();

  const PageHeaderComponent = FEAT_FLAG_NEW_PAGE_HEADER
    ? PageHeaderNew
    : PageHeaderLegacy;

  return <PageHeaderComponent {...props} />;
}

PageHeader.propTypes = {
  style: PropTypes.object,
  basketHidden: PropTypes.bool,
  languagePagePickerHidden: PropTypes.bool,
  navigationHidden: PropTypes.bool,
  loginMenuHidden: PropTypes.bool,
  actionIcons: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  phoneNumber: PropTypes.string,
  mrktClassNamePhone: PropTypes.string,
  mrktClassNameMail: PropTypes.string,
};

PageHeader.defaultProps = {
  style: {},
  basketHidden: false,
  languagePagePickerHidden: false,
  navigationHidden: false,
  loginMenuHidden: false,
  actionIcons: null,
  phoneNumber: null,
  mrktClassNamePhone: null,
  mrktClassNameMail: null,
};

export default PageHeader;
