import { Grid } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

import PageMargins from '@/components/ui/PageMargins/PageMargins';

import Footer from './Footer/Footer';
import { Collapse, MenuWrapper } from './Menu.style';
import NavLinks from './NavLinks/NavLinks';
import SideSection from './SideSection/SideSection';

const Menu = ({ activeItem, open, navbarHeight }) => {
  const sideSectionCards =
    activeItem?.attributes?.sideSection?.navbarCards || [];
  const hasSideSection = !!sideSectionCards.length;
  const sideSectionType = sideSectionCards.every(
    item => item.cardSize === 'small'
  )
    ? 'small'
    : 'big';

  const partners = activeItem?.attributes?.partners?.partners?.data;
  const bottomLink = activeItem?.attributes.bottomLink;
  const menuRef = useRef(null);

  const handleTransitionEnd = () => {
    if (!open) {
      menuRef.current.scrollTop = 0;
    }
  };

  useEffect(() => {
    if (menuRef.current) {
      menuRef.current.scrollTop = 0;
    }
  }, [activeItem]);

  return (
    <Collapse
      active={open}
      navbarHeight={navbarHeight}
      onTransitionEnd={handleTransitionEnd}>
      <MenuWrapper direction="row" sideSection={hasSideSection} ref={menuRef}>
        <PageMargins style={{ flex: 1 }}>
          <Grid container bgcolor="common.white">
            <Grid
              xs={hasSideSection}
              item
              container
              py={3}
              pr={hasSideSection ? 7 : 0}
              rowSpacing={3}>
              <NavLinks
                menuItems={activeItem?.attributes?.navbar_links?.data}
                hasSideSection={hasSideSection}
                sideSectionType={sideSectionType}
              />
              <Footer
                hasSideSection={hasSideSection}
                sideSectionType={sideSectionType}
                partners={partners}
                bottomLink={bottomLink}
              />
            </Grid>
            <Grid item xs={sideSectionType === 'small' ? 3.5 : 5.9}>
              <SideSection
                hasSideSection={hasSideSection}
                sideSectionType={sideSectionType}
                sideSectionCards={sideSectionCards}
              />
            </Grid>
          </Grid>
        </PageMargins>
      </MenuWrapper>
    </Collapse>
  );
};

Menu.propTypes = {
  activeItem: PropTypes.shape({
    attributes: PropTypes.shape({
      navbar_links: PropTypes.shape({
        data: PropTypes.arrayOf(
          PropTypes.shape({
            id: PropTypes.number.isRequired,
            attributes: PropTypes.shape({
              title: PropTypes.string.isRequired,
              link: PropTypes.string.isRequired,
              navbar_links: PropTypes.shape({
                data: PropTypes.arrayOf(
                  PropTypes.shape({
                    id: PropTypes.number.isRequired,
                    attributes: PropTypes.shape({
                      title: PropTypes.string.isRequired,
                      link: PropTypes.string.isRequired,
                    }).isRequired,
                  })
                ),
              }),
            }).isRequired,
          })
        ),
      }),
      sideSection: PropTypes.shape({
        navbarCards: PropTypes.arrayOf(
          PropTypes.shape({
            cardSize: PropTypes.oneOf(['big', 'small']).isRequired,
            image: PropTypes.shape({
              data: PropTypes.object,
            }).isRequired,
            title: PropTypes.string.isRequired,
            content: PropTypes.string,
            navbarButton: PropTypes.shape({
              link: PropTypes.string.isRequired,
              icon: PropTypes.string,
              iconPosition: PropTypes.string,
              text: PropTypes.string,
            }),
          })
        ),
      }),
      partners: PropTypes.shape({
        partners: PropTypes.shape({
          data: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.number.isRequired,
              attributes: PropTypes.shape({
                width: PropTypes.number.isRequired,
                height: PropTypes.number.isRequired,
              }).isRequired,
            })
          ),
        }),
      }),
      bottomLink: PropTypes.string,
    }),
  }),
  navbarHeight: PropTypes.number.isRequired,
};

export default Menu;
