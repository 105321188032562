import { formatPhone } from '@/helpers/mappers/personalData';

export const getPayload = (
  {
    phone,
    email,
    company,
    message,
    forename,
    surname,
    privacyConsent,
    subject,
  },
  { gclid, msclkid, utmParams }
) => {
  return {
    consent: privacyConsent,
    name: `${forename} ${surname}`,
    company,
    email,
    phone: formatPhone(phone.number, phone.countryCode),
    message,
    gclid,
    msclkid,
    ...utmParams,
    ...(subject && { subject }),
  };
};
