import { Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';

import {
  trackMenuMobileEmailClick,
  trackMobilePhoneClick,
} from '@/helpers/dataLayer';
import { updatePhoneNumbers } from '@/helpers/utils';
import useFormatMessage from '@/hooks/useFormatMessage';
import usePhoneNumber from '@/hooks/usePhoneNumber';

import {
  Contact,
  ContactContent,
  ContactIcon,
  ContactUsWrapper,
  SectionHeader,
} from './MobileContactUs.style';

const MobileContactUs = () => {
  useEffect(() => {
    updatePhoneNumbers();
  });
  const formatMessage = useFormatMessage();
  const [phoneNumber, phoneNumberLink] = usePhoneNumber();

  const email = formatMessage('header_email');
  const emailSubject = formatMessage('header_email_subject');
  const emailBody = formatMessage('header_email_body');

  return (
    <>
      <SectionHeader variant="h4" component="div">
        <FormattedMessage id="header_question" />
      </SectionHeader>
      <ContactUsWrapper>
        <Contact href={phoneNumberLink} onClick={trackMobilePhoneClick}>
          <ContactIcon name="phone" />
          <ContactContent
            component="span"
            variant="body2"
            className="mrsCallTracking">
            {phoneNumber}
          </ContactContent>
        </Contact>
        <Contact
          component="a"
          variant="body2"
          href={encodeURI(
            `mailto:${email}?subject=${emailSubject}&body=${emailBody}`
          )}
          onClick={trackMenuMobileEmailClick}>
          <ContactIcon name="mail" />
          <ContactContent component="span" variant="body2">
            {email}
          </ContactContent>
        </Contact>
        <Typography variant="label">
          <FormattedMessage id="header_availability" />
        </Typography>
      </ContactUsWrapper>
    </>
  );
};

export default MobileContactUs;
