import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Stack } from '@mui/material';
import Link from 'next/link';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import React from 'react';

import ClickAwayListener from '@/components/ui/ClickAwayListener/ClickAwayListener';
import SvgIcon from '@/components/ui/SvgIcon/SvgIcon';
import getConfig from '@/configs/global';
import { useBasketContext } from '@/hooks/useBasketContext';
import { useBasketPreviewContext } from '@/hooks/useBasketPreviewContext';
import useFormatMessage from '@/hooks/useFormatMessage';
import { useMobileMediaQuery } from '@/hooks/useMobileMediaQuery';

import { Badge, BasketText, Wrapper } from './HeaderBasket.style';
import BasketPreview from './lib/BasketPreview';

const HeaderBasket = ({ 'data-cy': dataCy, className }) => {
  const {
    publicRuntimeConfig: { FEAT_FLAG_NEW_PAGE_HEADER },
  } = getConfig();
  const { requestsInBasketCount } = useBasketContext();
  const {
    openBasketPreview,
    closeBasketPreview,
    basketPreviewOpen: open,
    setBasketPreviewHovered,
  } = useBasketPreviewContext();
  const mobileMediaQuery = useMobileMediaQuery();
  const { pathname } = useRouter();
  const formatMessage = useFormatMessage();

  const pathWithoutBasketPreview = ['/basket', '/order-details'].some(el =>
    pathname.includes(el)
  );

  const handleClose = e => {
    // prevent preview to be closed on blur - after interacting with elements inside preview
    if (open && e.type !== 'blur') {
      closeBasketPreview();
    }
  };

  const basketIcon = FEAT_FLAG_NEW_PAGE_HEADER ? (
    <Badge badgeContent={requestsInBasketCount} color="secondary">
      <ShoppingCartIcon fontSize="small" color="primary" />
    </Badge>
  ) : (
    <Badge badgeContent={requestsInBasketCount} color="secondary">
      <SvgIcon name="shoppingCart" color="primary" />
    </Badge>
  );

  if (pathWithoutBasketPreview) {
    return (
      <Wrapper
        data-cy={dataCy}
        className={className}
        style={{ cursor: 'auto' }}>
        {basketIcon}
      </Wrapper>
    );
  }

  if (mobileMediaQuery) {
    return (
      <Link href="/basket">
        <Wrapper data-cy={dataCy} className={className}>
          {basketIcon}
        </Wrapper>
      </Link>
    );
  }

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Stack direction="row" alignItems="center" spacing={0.5}>
        <BasketPreview
          open={open}
          onClose={handleClose}
          // written as function not to pass event parameter from onOpen
          onOpen={() => openBasketPreview()}
          basketEmpty={requestsInBasketCount === 0}
          onMouseEnter={() => setBasketPreviewHovered(true)}
          onMouseLeave={() => setBasketPreviewHovered(false)}>
          <Wrapper data-cy={dataCy} className={className}>
            {basketIcon}
          </Wrapper>
        </BasketPreview>
        {FEAT_FLAG_NEW_PAGE_HEADER && !mobileMediaQuery && (
          <BasketText
            component="div"
            color="text.secondary"
            onMouseEnter={() => openBasketPreview()}>
            {formatMessage('header_basketLabel')}
          </BasketText>
        )}
      </Stack>
    </ClickAwayListener>
  );
};

HeaderBasket.propTypes = {
  className: PropTypes.string,
  'data-cy': PropTypes.string,
};

HeaderBasket.defaultProps = {
  className: '',
  'data-cy': '',
};

export default HeaderBasket;
