import PropTypes from 'prop-types';
import React from 'react';

import { StyledTooltip } from './Tooltip.style';

const Tooltip = ({ children, ...props }) => {
  return <StyledTooltip {...props}>{children}</StyledTooltip>;
};

Tooltip.propTypes = {
  children: PropTypes.node,
};

export default Tooltip;
