import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box } from '@mui/material';
import Link from 'next/link';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { trackMenuItemClick } from '@/helpers/dataLayer';
import {
  sortByName,
  sortByNameAndChildren,
} from '@/helpers/sortNavigationLinks';

import MobileContactUs from './MobileContactUs';
import {
  BackLink,
  Level,
  Levels,
  Links,
  LinkWrapper,
  SectionHeader,
  Wrapper,
} from './MobileNavigation.style';

const MobileNavigation = ({
  closeNavigation,
  className,
  links,
  bottomLinks,
  sortLinks,
  pagesTitle,
}) => {
  const [activeLevel, setActiveLevel] = useState(1);
  const [level1, setLevel1] = useState(null);
  const [level2, setLevel2] = useState(null);

  const getChildren = link => {
    if (!link) {
      return [];
    }
    return link.children;
  };

  const scrollToTop = () => {
    const overlay = document.getElementById('overlayWrapper');
    overlay.scrollTop = 0;
  };

  const handleClickOnLevel1 = link => {
    setLevel1(link);
    setLevel2(null);
    setActiveLevel(2);

    scrollToTop();
  };

  const handleClickOnLevel2 = link => {
    setLevel2(link);
    setActiveLevel(3);

    scrollToTop();
  };

  const renderLink = ({ link, onClick, trackEvent = true }) => {
    const { name, children, href } = link;
    const key = `${name}-${href}`;

    if (children && children.length) {
      return (
        <LinkWrapper
          component="div"
          variant="body1"
          key={key}
          onClick={() => {
            onClick(link);
            if (trackEvent) {
              trackMenuItemClick();
            }
          }}
          data-cy={`mobile nav: ${name}`}>
          {name}
          <ChevronRightIcon color="primary" />
        </LinkWrapper>
      );
    }

    const onClickHandler = event => {
      if (Object.prototype.hasOwnProperty.call(link, 'onClick')) {
        link.onClick(event);
      }

      if (trackEvent) {
        trackMenuItemClick();
      }

      closeNavigation();
    };

    return (
      <LinkWrapper
        key={key}
        component={Link}
        href={href}
        variant="body1"
        data-cy={`mobile nav: ${name}`}
        onClick={onClickHandler}>
        {name}
      </LinkWrapper>
    );
  };

  const renderLevel1 = () => {
    const maybeSortedLinks = sortLinks ? sortByName(links) : links;

    return (
      <Level>
        <MobileContactUs />
        <SectionHeader variant="h4" component="div">
          {pagesTitle}
        </SectionHeader>
        <Links active={activeLevel === 1}>
          {maybeSortedLinks.map(link =>
            renderLink({ link, onClick: handleClickOnLevel1 })
          )}
          {bottomLinks && bottomLinks.length > 0 && (
            <Box mt={6.5}>
              {bottomLinks.map(link =>
                renderLink({
                  link,
                  onClick: handleClickOnLevel1,
                  trackEvent: false,
                })
              )}
            </Box>
          )}
        </Links>
      </Level>
    );
  };

  const renderLevel2 = () => (
    <Level>
      <BackLink variant="h4" component="div" onClick={() => setActiveLevel(1)}>
        <ChevronLeftIcon color="primary" />
        {level1 && level1.name}
      </BackLink>
      <Links active={activeLevel === 2}>
        {level1 && level1.href && (
          <LinkWrapper
            component={Link}
            href={level1.href}
            variant="body1"
            onClick={() => {
              closeNavigation();
              trackMenuItemClick();
            }}
            className={`mrkt-head_kat_${level1.href.replace(/\//g, '')}`}
            data-cy={`mobile nav: all ${level1.name}`}>
            <div>
              <FormattedMessage id="header_allin" /> {level1.name}
            </div>
          </LinkWrapper>
        )}
        {sortByNameAndChildren(getChildren(level1)).map(link =>
          renderLink({ link, onClick: handleClickOnLevel2 })
        )}
      </Links>
    </Level>
  );

  const renderLevel3 = () => (
    <Level>
      <BackLink variant="h4" component="div" onClick={() => setActiveLevel(2)}>
        <ChevronLeftIcon color="primary" />
        {level2 && level2.name}
      </BackLink>
      <Links active={activeLevel === 3}>
        {level2 && level2.href && (
          <LinkWrapper
            component={Link}
            href={level2.href}
            variant="body1"
            onClick={() => {
              closeNavigation();
              trackMenuItemClick();
            }}
            className="mrkt-third-level-category-head">
            <div>
              <FormattedMessage id="header_allin" /> {level2.name}
            </div>
          </LinkWrapper>
        )}
        {sortByName(getChildren(level2)).map(link =>
          renderLink({ link, onClick: closeNavigation })
        )}
      </Links>
    </Level>
  );

  return (
    <Wrapper className={className} data-cy="mobile navigation">
      <Levels activeLevel={activeLevel}>
        {renderLevel1()}
        {renderLevel2()}
        {renderLevel3()}
      </Levels>
    </Wrapper>
  );
};

const navigationLinks = PropTypes.arrayOf(
  PropTypes.shape({
    /** Displayed name of link. */
    name: PropTypes.string.isRequired,
    /** Url path as computed */
    href: PropTypes.string,
    /** 2nd layer navigation links */
    children: PropTypes.array,
  })
);

MobileNavigation.propTypes = {
  /** Css theme. */
  className: PropTypes.string,
  /** Structure of 1st, 2nd and 3rd navigation links. */
  links: navigationLinks.isRequired,
  /** Structure of bottom navigation links. */
  bottomLinks: navigationLinks,
  /** Boolean flag to sort 1st level links by name or not */
  sortLinks: PropTypes.bool,
  /** Function to close the mobile nav */
  closeNavigation: PropTypes.func.isRequired,
  /** Title of the pages */
  pagesTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
};

MobileNavigation.defaultProps = {
  bottomLinks: [],
  className: '',
  sortLinks: true,
};

export default MobileNavigation;
