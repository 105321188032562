import { styled } from '@mui/material/styles';

export const WrapperNewHeader = styled('a')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const Wrapper = styled('a')(() => ({
  height: 24,
  width: 24,
}));
